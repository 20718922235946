import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import App from './App';
import rootReducer from './reducers';

import './index.scss';
import reportWebVitals from './reportWebVitals';

// This removes the warnings about the styled.components within other components
let warn = console.warn;
console.warn = (msg, ...other) => {
	if (msg.toLocaleLowerCase().includes('styled.div')) {}
	else warn(msg, ...other);
};

export const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(thunk),
		...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
		//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,

	document.getElementById('root') || document.createElement('div') // for testing purposes
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
