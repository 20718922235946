import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '../../components/Button/Button';
import ContactTemplate from '../ContactTemplate/ContactTemplate';
import ContactProTemplate from '../ContactProTemplate/ContactProTemplate';
import ColumnsTemplate from '../ColumnsTemplate/ColumnsTemplate';
import VideoTemplate from '../VideoTemplate/VideoTemplate';
import JsonTemplate from '../JsonTemplate/JsonTemplate';
import { getValidUrl } from '../../utils/getValidUrl';
import { useTranslation } from '../../utils/useTranslation';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import styles from './Template.module.scss';
import imageTemplate from '../../assets/icons/image-template.svg';
// import playCircle from '../../assets/icons/play-circle.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import getOptimizedImage from '../../utils/getOptimizedImage';
import Lottie from 'react-lottie';
import HtmlTemplate from '../HtmlTemplate/HtmlTemplate';
import { templateBackground } from '../../utils/templateBackground';

/* Render an individual scene
	 Called by:
	 	Canvas.js (obsolete with React-Flow)
		RenderScene.js
		Preview.js (obsolete with preview in widget) */
function Template(props) {

	const t = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [hoverIndex, setHoverIndex] = useState(false);
	const [hoverBack, setHoverBack] = useState(false);
	const [fileData, setFileData] = useState('');
	const [isLottie, setIsLottie] = useState(false);
	const minWidth = props.scene.template === 'text_image' ? '110px' : props.scene.answers.length === 4 ? '80px' : '';
	const POSITION_CENTRAL = 'xMidYMid slice';

	const JSON_EXTENSION = 'json';
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: fileData,
		rendererSettings: {
			preserveAspectRatio: POSITION_CENTRAL,
		},
	};

	useEffect(() => {
		let image = props?.scene?.imageUrl;
		let imageExtension = image.split('.').pop();

		if (imageExtension === JSON_EXTENSION) {
			setIsLottie(true);
			readJsonFile(props?.scene?.imageUrl);
		} else {
			setIsLottie(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.scene.imageUrl]);

	function renderImage() {
		let img;
		if (props.scene.imageUrl !== '') {
			/* let tmp = props.scene.imageUrl.split('/');
			tmp[5] = 'upload/ar_0.77,c_crop'; */
			let image = getOptimizedImage(props.scene.imageUrl, 300);
			img = <img src={image} alt={props.scene.imageDescription ? props.scene.imageDescription : "sceneImg"} className={styles.image} />;
		} else {
			img = <img src={imageTemplate} alt="sceneImg" className={classNames(styles.image, styles.imageTemplate)} />;
		}

		return (
			<>
				{img}
				{props.scene.price && (
					<span
						className={styles.priceBubble}
						style={{ backgroundColor: props.project?.general?.buttonBackgroundColor?.hex }}
					>
						<span className={styles.priceLabel} style={{ color: props.project?.general?.buttonColor?.hex }}>
							{t.template.priceFrom}
						</span>
						<span className={styles.price} style={{ color: props.project?.general?.buttonColor?.hex }}>
							{props.scene.price}
						</span>
					</span>
				)}
			</>
		);
	}

	function getBackroundStyles(index) {
		return props.project?.general?.buttonBackgroundColor?.hex?.hoverIndex === index
			? lightenDarkenColor(props.project?.general?.buttonBackgroundColor?.hex, -40)
			: props.project?.general?.buttonBackgroundColor?.hex;
	}

	function isFirstScene() {
		return props.firstSceneId === props.scene._id;
	}

	function readJsonFile(url) {
		readTextFile(`${url}`, function(text) {
			var data = JSON.parse(text); //parse JSON
			setFileData(data);
		});
	}

	function readTextFile(file, callback) {
		var rawFile = new XMLHttpRequest();
		rawFile.overrideMimeType('application/json');
		rawFile.open('GET', file, true);
		rawFile.onreadystatechange = function() {
			if (rawFile.readyState === 4 && rawFile.status === 200) {
				callback(rawFile.responseText);
			}
		};
		rawFile.send(null);
	}

	if (props.templateType === 'contact') {
		return (
			<ContactTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				hideSceneName={props.hideSceneName}
				project={props.project}
				history={props.history}
				showAudioplayer={props.showAudioplayer}
				autoplay={props.autoplay}
			/>
		);
	} else if (props.templateType === 'contactPro') {
		return (
			<ContactProTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				hideSceneName={props.hideSceneName}
				project={props.project}
				history={props.history}
				showAudioplayer={props.showAudioplayer}
				autoplay={props.autoplay}
			/>
		);
	} else if (props.templateType === 'html') {
		return (
			<HtmlTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				hideSceneName={props.hideSceneName}
				project={props.project}
				isFirstScene={isFirstScene()}
				sidebar={props.sidebar}
			/>
		);
	} else if (props.templateType === 'columns' || props.templateType === 'images') {
		return (
			<ColumnsTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				showAudioplayer={props.showAudioplayer}
				autoplay={props.autoplay}
				isFirstScene={isFirstScene()}
				sidebar={props.sidebar}
			/>
		);
	} else if (props.templateType === 'video') {
		return (
			<VideoTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				showVideo={props.showVideo}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
				sidebar={props.sidebar}
			/>
		);
	} else if (props.templateType === 'json') {
		return (
			<JsonTemplate
				scene={props.scene}
				children={props.children}  // obsolete with React-Flow
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				showVideo={props.showVideo}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
				sidebar={props.sidebar}
			/>
		);
	} else
		return (
			<React.Fragment>
				{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
					<ChevronLeft
						className={styles.backButton}
						onClick={() => props.goBack()}
						style={{
							fill:
								props.project?.general?.buttonBackgroundColor?.hex &&
								(hoverBack
									? lightenDarkenColor(props.project?.general?.buttonBackgroundColor?.hex, -40)
									: props.project?.general?.buttonBackgroundColor?.hex),
						}}
						onMouseEnter={() => setHoverBack(true)}
						onMouseLeave={() => setHoverBack(false)}
					/>
				)}
				{props.showAudioplayer && props.scene.audioFileUrl && (
					<AudioPlayer
						audioUrl={props.scene.audioFileUrl}
						autoplay={props.autoplay}
						isFirstScene={isFirstScene()}
						sidebar={props.sidebar}
					/>
				)}
				<div
					className={classNames(styles.template, {
						[styles.templateImage]: props.templateType === 'text_image',
					})}
					style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
				>
					<span
						className={classNames(styles.templateRow, { [styles.templateCol]: props.templateType === 'text_image' })}
					>
						{isLottie ? (
							<div className={(styles.lottie, styles.image)}>{fileData && <Lottie options={defaultOptions} />}</div>
						) : (
							props.templateType === 'text_image' && renderImage()
						)}
						{!props.hideSceneName && (
							<div className={styles.titleWrapper}>
								<span className={styles.title}>{props.scene && props.scene.title}</span>
							</div>
						)}
						{/* If templateType === 'video' the code should return above... */}
						{/*{console.log('templateType',props.templateType)}*/}
						{/*{props.templateType === 'video' && (*/}
						{/*	<img src={playCircle} width="30" style={{ marginBottom: '3px' }} alt="Play Icon" />*/}
						{/*)}*/}
						<h1
							className={classNames(styles.question, { [styles.questionImage]: props.templateType === 'text_image' })}
							style={{ color: props.project?.general?.color?.hex }}
						>
							{props.scene && props.scene.question}{' '}
						</h1>
						{(props.templateType === 'text_image' || props.templateType === 'text') &&
							props.scene?.richText && (
								<p
									className={props.templateType === 'text_image' ? styles.richText : styles.richTextTextScene}
									dangerouslySetInnerHTML={{ __html: props.scene.richText }}
									style={{ color: props.project?.general?.color?.hex }}
								></p>
							)}
						<div>
						{props.scene?.freeText?.description && (
							<p
								className={styles.richTextTextScene}
								dangerouslySetInnerHTML={{ __html: props.scene.freeText.description }}
								style={{ color: props.project?.general?.color?.hex }}
							></p>
						)}
					</div>
						<div
							className={classNames(styles.buttonsContainer, { [styles.flexCol]: props.templateType === 'text_image' })}
						>
							{props.scene &&
								props.scene.answers.map((answer, index) => (
									<div
										className={classNames(styles.buttonWrapper, {
											[styles.buttonWrapperSmall]: props.scene && props.scene.answers.length > 3,
										})}
										key={index}
									>
										<Button
											key={answer.answer + props.scene._id}
											label={answer.answer}
											secondary
											onClick={() => {
												if (answer.sceneId) {
													props.goTo(answer.sceneId, answer.answer);
												} else if (answer.url) {
													window.open(getValidUrl(answer.url), '_blank');
												}
											}}
											onMouseEnter={() => {
												if (props.setChildIndex) props.setChildIndex(index);
												setHoverIndex(index);
											}}
											onMouseLeave={() => {
												if (props.setChildIndex) props.setChildIndex(null);
												setHoverIndex(false);
											}}
											style={{
												minWidth: minWidth,
												color: props.project?.general?.buttonColor?.hex,
												backgroundColor: getBackroundStyles(index),
											}}
										/>
									</div>
								))}
						</div>
					</span>
					{props.children}  {/* obsolete with React-Flow */}
				</div>
			</React.Fragment>
		);
}

export default Template;
