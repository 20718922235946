import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner-svg';
import 'react-loader-spinner-svg/dist/loader/css/react-spinner-loader.css';
import Lottie from 'react-lottie';
import { ReactComponent as ImageUploadIco } from '../../assets/icons/image-upload.svg';
import { showModal, hideModal } from '../../actions/modalActions';
import { fetchDeleteImage } from '../../actions/scenesActions';
import { MAX_IMAGE_UPLOAD_SIZE_BYTES } from '../../utils/constants/maxUploadSize';
import { useTranslation } from '../../utils/useTranslation';
import styles from './ImageUpload.module.scss';

const ENVIRONMENT = process.env.REACT_APP_CURRENT_ENV;
const CLOUDINARY_PROD_PRESET = process.env.REACT_APP_CLOUDINARY_PROD_PRESET;
const CLOUDINARY_DEV_PRESET = process.env.REACT_APP_CLOUDINARY_DEV_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

const LOTTIE_TYPE = 'application/json';
// const IMAGE_TYPE = 'image/jpeg';

/* called by Answer.js, ImageBox.js and ImageTextBox.js */
function ImageUpload({ onUpload, imageUrl, token, index }) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [fileData, setFileData] = useState();
	const [isLottie, setIsLottie] = useState(false);

	useEffect(() => {
		const JSON_EXTENSION = 'json';
		let imageExtension = imageUrl && imageUrl.split('.').pop();
		if (imageExtension === JSON_EXTENSION) {
			setIsLottie(true);
			readJsonFile(imageUrl);
		} else {
			setIsLottie(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageUrl]);

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function handleChange(e) {
		if (e.target.files.length) {
			const file = e.target.files[0]
			if (file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
				showPopup(t.imageUpload.imageMaxSizeTitle);
				return
			}
			handleUpload(file)
				.then((res) => {
					res.json().then((resJSON) => {
						onUpload(resJSON.secure_url, resJSON.public_id);
						setLoading(false);
					});
				})
				.catch(() => {
					setLoading(false);
					alert('Upload failed');
				});
		}
	}

	async function handleUpload(file) {
		const formData = new FormData();
		formData.append('file', file);
		if (ENVIRONMENT === 'development') {
			formData.append('upload_preset', CLOUDINARY_DEV_PRESET);
		} else {
			formData.append('upload_preset', CLOUDINARY_PROD_PRESET);
		}
		formData.append('cloud_name', CLOUDINARY_CLOUD_NAME);
		if (file.type === LOTTIE_TYPE) {
			formData.append('resource_type', 'raw');
		}
		setLoading(true);
		let url = getFileUrl(file.type);
		let upload =  await fetch(url, {
			method: 'POST',
			body: formData,
		});
		// clear input value so user can re-upload same image if desired
		document.getElementById('upload-button' + index).value= null;
		if (upload) {
			if (imageUrl) await fetchDeleteImage(token, imageUrl)
					.catch((error) => console.log('error: ', error));
			return upload;
		}
	}

	function readJsonFile(url) {
		readTextFile(`${url}`, function (text) {
			const data = JSON.parse(text); //parse JSON
			setFileData(data);
		});
	}

	function readTextFile(file, callback) {
		const rawFile = new XMLHttpRequest();
		rawFile.overrideMimeType('application/json');
		rawFile.open('GET', file, true);
		rawFile.onreadystatechange = function () {
			if (rawFile.readyState === 4 && rawFile.status === 200) {
				callback(rawFile.responseText);
			}
		};
		rawFile.send(null);
	}

	function getFileUrl(fileType) {
		if (fileType === LOTTIE_TYPE) {
			// upload url for raw files
			return 'https://api.cloudinary.com/v1_1/five-digital-gmbh/upload';
		}
		// upload url for images
		return 'https://api.cloudinary.com/v1_1/five-digital-gmbh/image/upload';
	}

	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: fileData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<React.Fragment>
			<label className={styles.inputLabel} htmlFor={'upload-button' + index}>
				{imageUrl ? (
					<>
						{!loading && (
							<>
								{isLottie ? (
									<Lottie options={lottieOptions} />
								) : (
									<img src={imageUrl} className={styles.imagePreview} alt="sceneImage"/>
								)}
							</>
						)}
						<Loader type="TailSpin" color="#00BFFF" height={40} width={40} visible={loading} />
					</>
				) : (
					<>
						{!loading && (
							<>
								<ImageUploadIco className={styles.icon} />
								<span className={styles.span}>Upload</span>
							</>
						)}
						<Loader type="TailSpin" color="#00BFFF" height={40} width={40} visible={loading} />
					</>
				)}
			</label>
			<input type="file" id={'upload-button' + index} style={{ display: 'none' }} onChange={handleChange} />
		</React.Fragment>
	);
}

export default ImageUpload;
