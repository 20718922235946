import { useState } from "react";
import { Col, Container, Form, FormControl, Row } from "react-bootstrap";
// import Button from '../Button/Button';
import RichText from "../RichText/RichText";
import { useTranslation } from '../../utils/useTranslation';
import styles from './EditContactField.module.scss';

// characters not allowed in object keys: first two not allowed by MongoDB, second two not allowed in JSON;
const NOT_ACCEPTED_CHARACTERS = ['.', '$', '"', '\\', ];

// edit fields of contactPro form; called from ContactProBox.js
const EditContactField = ({ selectedScene, submitField, currentField = {}, fieldIndex }) => {
  const t = useTranslation();
  const [formSelect, setFormSelect] = useState(currentField.type || 'shortText');
  const [fieldName, setFieldName] = useState(currentField.name || t.sceneTab.fieldName);
  const [fieldNameErrMsg, setFieldNameErrMsg] = useState('');
  const [placeholder, setPlaceholder] = useState(currentField.placeholder || t.sceneTab.placeholder);
  const [requiredField, setRequiredField] = useState(currentField.requiredField || false);
  const [options, setOptions] = useState(currentField.options || t.sceneTab.options);
  const [checkboxText, setCheckboxText] = useState(currentField.checkboxText || t.sceneTab.checkboxText);
  const [fieldWidth, setFieldWidth] = useState(currentField.fieldWidth || '33%');

  const onFormSubmit = (e) => {
    // e.preventDefault();
    // if new focus is still inside this form, ignore the onBlur()
    if (e.currentTarget.parentNode.contains(e.relatedTarget)) return;
    const updatedField = {}
    updatedField.type = formSelect;
    updatedField.name = fieldName;
    updatedField.placeholder = placeholder;
    updatedField.requiredField = requiredField;
    updatedField.options = options;
    updatedField.checkboxText = checkboxText;
    updatedField.fieldWidth = fieldWidth;
    submitField(updatedField, fieldIndex);
  }

  return <Container className={styles.container} onBlur={onFormSubmit}>
    <Form
      //onSubmit={onFormSubmit}
      className={styles.form}
    >
      <Form.Group as={Row} className='justify-content-between'>
        <Form.Label column sm="3">
          {t.sceneTab.type}
        </Form.Label>
        <Col sm="9">
          <Form.Select aria-label="select form type"
                       onChange={(e)=> setFormSelect(e.target.value)}
                       defaultValue={formSelect}
                       size='sm'
          >
            <option value="shortText">{t.sceneTab.shortText}</option>
            <option value="textarea">{t.sceneTab.textarea}</option>
            <option value="dropdown">{t.sceneTab.dropdown}</option>
            <option value="checkbox">{t.sceneTab.checkbox}</option>
            <option value="multipleChoice">{t.sceneTab.multipleChoice}</option>
            <option value="date">{t.sceneTab.date}</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='justify-content-between'>
        <Form.Label column sm="4">
          {t.sceneTab.fieldName}
        </Form.Label>
        <Col sm="8">
          <FormControl value={fieldName} size='sm'
                       onChange={(e) => {
                         setFieldNameErrMsg('')
                         let newStr = e.target.value;
                         /** It shows user the info about unallowed characters and removes the message after 3 seconds */
                         const validateCharacters = (character) => {
                           if (newStr.includes(character)) {
                             setFieldNameErrMsg(t.sceneTab.forbiddenCharacters);
                             newStr = newStr.replaceAll(character, '');
                             setTimeout(() => setFieldNameErrMsg(''), 3000);
                           };
                         };
                         NOT_ACCEPTED_CHARACTERS.some(validateCharacters);
                         setFieldName(newStr);
                       }}
          />
        </Col>
      </Form.Group>
      {fieldNameErrMsg && <div className={styles.fieldNameErrMsg}>{fieldNameErrMsg}</div>}
      {(formSelect === 'shortText' || formSelect === 'textarea' || formSelect === 'dropdown' || formSelect === 'date') &&
        <Form.Group as={Row} className='justify-content-between'>
          <Form.Label column sm="4">
            {t.sceneTab.placeholder}
          </Form.Label>
          <Col sm="8">
            <FormControl value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} size='sm' />
          </Col>
        </Form.Group>
      }
      <Form.Group as={Row} className='justify-content-between align-items-center'>
        <Form.Label column sm="8">
          {t.sceneTab.requiredField}
        </Form.Label>
        <Col sm="4">
          <Form.Switch checked={requiredField} onChange={() => setRequiredField(!requiredField)}
                       id='form-switch' size='sm' />
        </Col>
      </Form.Group>
      {(formSelect === 'dropdown' || formSelect === 'multipleChoice') &&
        <Form.Group as={Row} className='justify-content-between align-items-start'>
          <Form.Label column sm="4">
            {t.sceneTab.options}
          </Form.Label>
          <Col sm="8">
            <FormControl as="textarea" rows={5} value={options}
                         onChange={(e) => setOptions(e.target.value)} size='sm' />
          </Col>
        </Form.Group>
      }
      {(formSelect === 'checkbox') &&
        <Form.Group>
          <Form.Label>
            {t.sceneTab.checkboxText}
          </Form.Label>
          <RichText
            selectedScene={selectedScene}
            onChange={(e) => setCheckboxText(e)}
            onBlur={(link) => { if (link) setCheckboxText(link) }}
            // onBlur={() => void(0)}  // we don't need this here...
            defaultValue={checkboxText}
            light
          />
        </Form.Group>
      }
      <Form.Group as={Row} className='justify-content-between mt-1'>
        <Form.Label column sm="5">
          {t.sceneTab.fieldWidth}
        </Form.Label>
        <Col sm="7">
          <Form.Select aria-label="select field width"
                       onChange={(e)=> setFieldWidth(e.target.value)}
                       defaultValue={fieldWidth}
          >
            <option value="33%">33%</option>
            <option value="50%">50%</option>
            <option value="66%">66%</option>
            <option value="100%">100%</option>
          </Form.Select>
        </Col>
      </Form.Group>

      {/* <Button type="submit" secondary small label={t.sceneTab.save}
				style={{ marginTop: '5px', marginLeft: '102px', fontSize: '12px', padding: '2px'}}
			/> */}

    </Form>
  </Container>
}

export default EditContactField;
