import { GET_SESSIONS, CAN_ACCESS, SESSIONS_ERROR } from '../actions/sessionsActions';

const initialState = [];

export default function analyticsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SESSIONS:
			return {
				data: action.payload.data,
				pagination: action.payload.pagination,
			};
		case CAN_ACCESS:
			return { ...state, sessionData: action.payload };
		case SESSIONS_ERROR:
			return initialState;
		default:
			return state;
	}
}
