import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScenes } from '../../actions/scenesActions';
import Dashboard from '../../components/Dashboard/Dashboard';
import Sidebar from '../Sidebar/Sidebar';
import Canvas from '../Canvas/Canvas';

// TODO: remove console.log

/**
	* Renders Canvas.js and Sidebar.js for editing a project (Berater)
	* Called by App.js.
	*/
function ProjectEditor() {
	const params = useParams();
	const dispatch = useDispatch();

	const token = useSelector((state) => state.auth.token);
	const scenes = useSelector((state) => state.scenes);
	console.log('scenes:', scenes)
	const [selectedSceneIds, setSelectedSceneIds] = useState([]);

	useEffect(() => {
		dispatch(getScenes(token, params.id));
	}, [dispatch, token, params.id]);

	return (scenes.data !== null) ? (
		<Dashboard>
			<Canvas
				scenesData={scenes.data}
				// sceneKey={scenes.key}  // ensures re-rendering after scene or answer update
				                       		// do we still need this? - commented out on 14.12.22
				setSelectedSceneIds={setSelectedSceneIds}
				selectedSceneIds={selectedSceneIds}
			/>
			<Sidebar selectedSceneIds={selectedSceneIds} setSelectedSceneIds={setSelectedSceneIds} projectId={params.id} />
		</Dashboard>
	) : (
		(window.location.href = '/projectList')
	);
}

export default ProjectEditor;
