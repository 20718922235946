import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './HtmlTemplate.module.scss';
import { templateBackground } from '../../utils/templateBackground';
// import { Helmet } from 'react-helmet';  rendering of custom html is currently disabled

const DEFAULT_HTML_IMAGE_URL = 'https://res.cloudinary.com/five-digital-gmbh/image/upload/v1660210182/digital_berater/HTML-DefaultImage_kqvvg5.png'

/* Rendering of custom html and css on preview has been disabled as
   per client's request; see commented section below. */
function HtmlTemplate(props) {

	function renderAnswers(answers) {
		return (
			<div className={classNames(styles.buttonsContainer)}>
				{answers.map( answer => {
					return (	
						<Button
							key={answer.answer + props.scene._id}
							label={answer.answer}
							secondary
							style={{
								minWidth: (answers.length > 3) && '78px',
								color: props?.project?.general?.buttonColor?.hex,
								backgroundColor: props?.project?.general?.buttonBackgroundColor?.hex &&
									props.project.general.buttonBackgroundColor.hex
							}}
						/>
					);
				})}
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				className={classNames(styles.template)}
				id={`scene-${props.scene._id.toString()}`}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				<span className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question)}
						style={{color: props?.project?.general?.color.hex}}
					>
						{props.scene && props.scene.question}{' '}
					</h1>

					
					<div className={styles.customHtml}>
						<img className={styles.defaultHtmlImg} src={DEFAULT_HTML_IMAGE_URL} alt="default html" />
						{/* rendering of custom html and css on preview has been disabled as per client request
						{props.scene.customHtml !== '' ? <div dangerouslySetInnerHTML={{__html: props.scene.customHtml}} />
						: <img className={styles.defaultHtmlImg} src={DEFAULT_HTML_IMAGE_URL} alt="default html" />}
						<style>{props.scene.hasCustomCss && props.scene.customCss}</style>
						<Helmet>
							<script>{props.scene.hasCodeSnippet && props.scene.codeSnippet}</script>
						</Helmet> */}
					</div>

					{props.scene?.answers && renderAnswers(props.scene.answers)}
				</span>

				{props.children}   {/* obsolete with React-Flow */}
			</div>
		</React.Fragment>
	);
}

export default HtmlTemplate;