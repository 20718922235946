import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from './containers/Login/Login';
import Register from './containers/Register/Register';
import AccountVerified from './containers/AccountVerified/AccountVerified';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import PrivateRoute from './utils/PrivateRoute';
import ProjectList from './containers/ProjectList/ProjectList';
import ProjectEditor from './containers/ProjectEditor/ProjectEditor';
import Preview from './containers/Preview/Preview';
import Modal from './containers/Modal/Modal';
import Admin from './containers/Admin/Admin';
import Dashboard from './components/Dashboard/Dashboard';
import Analytics from './components/Analytics/Analytics';
import Sessions from './components/Sessions/Sessions';
import Inquiries from './components/Inquiries/Inquiries';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Redirect to="/login" />
				</Route>
				<Route path="/register/:brandname">
					<Register />
				</Route>
				<Route path="/register">
					<Register />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/accountVerified">
					<AccountVerified />
				</Route>
				<Route path="/previewPublic/:id/:widget">
					<Preview public widget />
				</Route>
				<Route path="/previewPublic/:id">
					<Preview public />
				</Route>
				<Route path="/forgotPassword">
					<ForgotPassword />
				</Route>
				<Route path="/changePassword">
					<ChangePassword />
				</Route>
				<Route path="/downloads">
					<Dashboard noScroll>
						<iframe
							src="https://www.five-digital.de/downloads"
							title="downloads"
							width="100%"
							height={window.innerHeight}
						/>
					</Dashboard>
				</Route>
				<Route path="/ersteschritte">
					<Dashboard noScroll>
						<iframe
							src="https://www.five-digital.de/erste-schritte"
							title="ersteschritte"
							width="100%"
							height={window.innerHeight}
						/>
					</Dashboard>
				</Route>
				<PrivateRoute path="/analytics">
					<Analytics />
				</PrivateRoute>
				<PrivateRoute path="/sessions/:beraterID">
					<Sessions />
				</PrivateRoute>
				<PrivateRoute path="/inquiries/:beraterID">
					<Inquiries />
				</PrivateRoute>
				<PrivateRoute path="/projectList">
					<ProjectList />
				</PrivateRoute>
				<PrivateRoute path="/ProjectEditor/:id">
					<ProjectEditor />
				</PrivateRoute>
				<PrivateRoute path="/preview/:id">
					<Preview />
				</PrivateRoute>
				<PrivateRoute path="/admin">
					<Admin />
				</PrivateRoute>
				<PrivateRoute path="/:email/projectList">
					<ProjectList />
				</PrivateRoute>

				<Redirect to="/" />
			</Switch>
			<Modal />
		</Router>
	);
}

export default App;
