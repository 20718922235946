import { GET_ANALYTICS, ANALYITIC_ERROR } from '../actions/analyticActions';

const initialState = [];

export default function analyticsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ANALYTICS:
			return action.payload;
		case ANALYITIC_ERROR:
			return initialState;
		default:
			return state;
	}
}
