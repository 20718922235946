import classNames from 'classnames';
import Navbar from '../../containers/Navbar/Navbar';

import styles from './Dashboard.module.scss';

function Dashboard(props) {
	return (
		<div className={styles.dashboard}>
			<Navbar />
			<div className={classNames(styles.container, props.noScroll && styles.noScroll)}>{props.children}</div>
		</div>
	);
}

export default Dashboard;
