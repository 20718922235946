import { useTranslation } from "./useTranslation";

const t = useTranslation;

export const getTemplateData = (projectId, templateType, { scenesLength, deltaX, deltaY, email }) => {
	switch (templateType) {
		case 'text':
		case 'text_image':
		case 'images':
		case 'columns':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().sceneTab.question} ${scenesLength + 1}`,
				answers: [{ answer: t().generalTab.yes }, { answer: t().generalTab.no }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'html':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().sceneTab.question} ${scenesLength + 1} - HTML`,
				answers: [{ answer: 'Ja, bitte!' }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'video':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: '',
				answers: [{ answer: t().generalTab.yes }, { answer: t().generalTab.no }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'contact': {
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				answers: { answer: 'Absenden' },
				question: `${t().sceneTab.question} ${scenesLength + 1}`,
				contact: {
					name: {
						value: true,
						name: 'Vorname',
					},
					surname: {
						value: true,
						name: 'Nachname',
					},
					email: {
						value: true,
						name: 'E-Mail-Adresse',
					},
					telephone: {
						value: true,
						name: 'Telefonnummer',
					},
					other: {
						value: false,
						name: 'Benutzerdefiniert',
					},
					tos: true,
					tosLink: 'Ich habe die Datenschutzhinweise gelesen und akzeptiere diese.',
					emailLink: email,
					ccEmail: '',
				},
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		}
		case 'contactPro': {
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				answers: { answer: 'Absenden' },
				question: `${t().sceneTab.question} ${scenesLength + 1}`,
				contactPro: {
					emailLink: email,
					ccEmail: '',
				},
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		}
		case 'json':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().sceneTab.question} ${scenesLength + 1}`,
				answers: [{ answer: '' }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
			case 'freeText':
				return {
					projectId,
					title: `${t().sceneTab.scene} ${scenesLength + 1}`,
					question: `${t().sceneTab.question} ${scenesLength + 1}`,
					freeText: {
						description: '',
						inputType: 'text',
						placeholder: t().sceneTab.placeholder,
						required: false,
						defaultAnswer: t().sceneTab.noAnswer
					},
					answers: { answer: t().jsonTemplate.continue },
					template: templateType,
					hasBackButton: !!scenesLength > 0,
					position: {
						x: deltaX,
						y: deltaY,
					},
				};
		default:
			return {};
	}
};
