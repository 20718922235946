import { SET_MUTE_BUTTON } from '../actions/audioplayerMuteButtonActions';

const initialState = {
	isMuted: false,
};

export default function audioplayerMuteButtonReducer(state = initialState, action) {
	switch (action.type) {
		case SET_MUTE_BUTTON:
			return { ...state, isMuted: action.isMuted };
		default:
			return state;
	}
}
