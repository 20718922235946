// eslint-disable-next-line import/no-anonymous-default-export
export default (showPlayButton) => {
	return {
		root: {
			border: 'none',
			boxShadow: 'none',
			background: 'inherit',
			width: '250px',
			height: '24px',
			bottom: '35px',
			right: '15px',
			paddingBottom: '20px',
			position: 'absolute',
			display: showPlayButton && 'none',
		},
		playIcon: {
			width: '23px',
			height: '20px',
			color: '#FFFFFF',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
			marginLeft: '80%',
			marginTop: '5%',
		},
		replayIcon: {
			width: '23px',
			height: '20px',
			color: '#FFFFFF',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
			marginLeft: '80%',
			marginTop: '5%',
		},
		pauseIcon: {
			width: '23px',
			height: '20px',
			color: '#FFFFFF',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
			marginLeft: '80%',
			marginTop: '5%',
		},
		volumeIcon: {
			width: '23px',
			height: '20px',
			color: '#FFFFFF',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
		},
		volumeSlider: { display: 'none' },
		volumeSliderContainer: { display: 'none' },
		progressTime: { display: 'none' },
		mainSlider: { display: 'none' },
	};
};
