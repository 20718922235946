import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import Input from '../../components/Input/Input';
import { useTranslation } from '../../utils/useTranslation';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import styles from './ContactProTemplate.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { templateBackground } from '../../utils/templateBackground';

/* The ContactProTemplate is only functional in the widget.
	 This component here is only to show preview on Canvas.js. */
function ContactProTemplate(props) {
	const t = useTranslation();

	return (
		<React.Fragment>
			{props.showAudioPlayer && props.scene.audioFileUrl && (
				<AudioPlayer audioUrl={props.scene.audioFileUrl} autoplay={props.autoplay} />
			)}
			{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
				<ChevronLeft
					className={styles.backButton}
					onClick={() => props.goBack()}
					style={{ fill:	props.project?.general?.buttonBackgroundColor?.hex }}
				/>
			)}
			<div
				className={classNames(styles.template)}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				<span className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question)}
						style={{ color:	props.project?.general?.color?.hex }}
					>
						{props.scene && props.scene.question}{' '}
					</h1>
					<div>
						{props.scene?.contactPro?.description && (
							<p
								className={styles.richText}
								dangerouslySetInnerHTML={{ __html: props.scene.contactPro.description }}
								style={{ color: props.project?.general?.color?.hex }}
							></p>
						)}
					</div>
					<div className={styles.inputRow}>
						<div className={styles.inputWrapper}>
							<Input
								type="text"
								value={''}
								onChange={() => {}}
								squared
								template
								placeholder={t.sceneTab.contactSection.namePlaceholderPro}
							/>
						</div>
					</div>
					<span className={styles.inputRow} style={{ display: 'block' }}>
						<Input
							type="text"
							value={''}
							onChange={() => {}}
							squared
							template
							placeholder={t.sceneTab.contactSection.emailPlaceholderPro}
						/>
					</span>
					<div className={styles.inputRow}>
						<div className={styles.inputWrapper}>
							<Input
								type="text"
								value={''}
								onChange={() => {}}
								squared
								template
								placeholder={t.sceneTab.contactSection.phonePlaceholderPro}
							/>
						</div>
					</div>
						<>
							<span className={styles.checkboxContainer} style={{ display: 'block' }}>
								<div className={styles.checkboxWrapper}>
									<input type="checkbox" checked={false} readOnly />
									<div
										dangerouslySetInnerHTML={{ __html: t.sceneTab.contactSection.TOSTextPro }}
										className={styles.checkboxLink}
										style={{
											color:
												props.project &&
												props.project.general &&
												props.project.general.color &&
												props.project.general.color.hex,
										}}
									></div>
								</div>
							</span>
						</>
					<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
						{props.scene &&
							props.scene.answers.map((answer, index) => (
								<div
									className={styles.buttonWrapper}
									key={index}
									style={{ marginTop: props.scene.contact?.tos && '8px' }}
								>
									<Button
										key={answer.answer + props.scene._id}
										label={answer.answer}
										secondary
										style={{
											minWidth: props.scene.answers.length === 4 && '80px',
											color:
												props.project &&
												props.project.general &&
												props.project.general.buttonColor &&
												props.project.general.buttonColor.hex,
											backgroundColor:
												props.project &&
												props.project.general &&
												props.project.general.buttonBackgroundColor &&
												props.project.general.buttonBackgroundColor.hex &&
												props.project.general.buttonBackgroundColor.hex
										}}
									/>
								</div>
							))}
					</span>
				</span>
				{props.children}   {/* obsolete with React-Flow */}
			</div>
		</React.Fragment>
	);
}

export default ContactProTemplate;
