import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Answer from '../../components/Answer/Answer';
import { updateSceneAnswer, updateAnswer, removeAnswer } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import { showModal, hideModal } from '../../actions/modalActions';

import styles from './AnswersBox.module.scss';
import { ReactComponent as PlusIco } from '../../assets/icons/plus-circle.svg';

function AnswersBox(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	const answers =
		props.selectedScene &&
		props.selectedScene.answers.map((answer, index) => {
			return (
				<Answer
					key={index}
					index={index}
					answer={answer}
					scenes={props.scenes}
					selectedScene={props.selectedScene}
					token={props.token}
					dispatch={props.dispatch}
					updateSceneAnswer={updateSceneAnswer}
					updateAnswer={updateAnswer}
					removeAnswer={removeAnswer}
				/>
			);
		});

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function addAnswer() {
		if (props.selectedScene) {
			if ((props.selectedScene.template === 'images' || props.selectedScene.template === 'html') && props.selectedScene.answers.length <= 7) {
				props.dispatch(
					updateAnswer(
						props.token,
						props.selectedScene._id,
						props.selectedScene.answers.length,
						{ answer: t.template.choose },
						projectHasDraftScenes
					)
				);
			} else if (props.selectedScene.answers.length <= 3) {
				props.dispatch(
					updateAnswer(
						props.token,
						props.selectedScene._id,
						props.selectedScene.answers.length,
						{ answer: t.template.choose },
						projectHasDraftScenes
					)
				);
			} else if (props.selectedScene.template === 'images' || props.selectedScene.template === 'html') {
				showPopup(t.sceneTab.maxEightButtons);
			} else {
				showPopup(t.sceneTab.maxFourButtons);
			}
		}
	}

	return (
		<SidebarBox>
			{answers}
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.sceneTab.addAnswer}</label>
				<PlusIco className={styles.plusIco} onClick={() => addAnswer()} />
			</div>
		</SidebarBox>
	);
}

export default AnswersBox;
