export const GET_USERS = 'GET_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_RESPONSE = 'USERS_RESPONSE';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets users error
 * @param {string} data
 * @returns {void}
 */
export const setUsersError = (data) => {
	return {
		type: USERS_ERROR,
		payload: data,
	};
};

/**
 * Sets users response
 * @param {string} response
 * @returns {void}
 */

export const setUsersResponse = (response) => {
	return {
		type: USERS_RESPONSE,
		payload: response,
	};
};

export const fetchUsers = async (token) => {
	try {
		const res = await fetch(apiUrl + '/users/all', {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Gets all users
 * @param {string} token auth token
 */
export const getUsers = (token) => {
	return (dispatch) => {
		fetchUsers(token).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setUsersError(resJSON.error));
				} else {
					dispatch({
						type: GET_USERS,
						payload: resJSON.users,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setUsersError(resJSON));
			}
		});
	};
};

export const fetchUpdateUsers = async (token, email, user) => {
	try {
		const res = await fetch(apiUrl + '/users/' + email, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify(user),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Update users
 * @param {string} token
 * @param {string} email
 * @param {object} user
 */
export const updateUsers = (token, email, user) => {
	return (dispatch) => {
		fetchUpdateUsers(token, email, user).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setUsersError(resJSON.error));
				} else {
					dispatch(setUsersResponse(resJSON.message));
					dispatch({
						type: UPDATE_USERS,
						payload: resJSON.user,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setUsersError(resJSON));
			}
		});
	};
};

export const fetchDeleteUsers = async (token, email) => {
	try {
		const res = await fetch(apiUrl + '/users/' + email, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Delete users
 * @param {string} token auth token
 * @param {string} email
 */
export const deleteUsers = (token, email) => {
	return (dispatch) => {
		fetchDeleteUsers(token, email).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setUsersError(resJSON.error));
				} else {
					dispatch(setUsersResponse(resJSON.message));
					dispatch({
						type: DELETE_USERS,
						payload: email,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setUsersError(resJSON));
			}
		});
	};
};
