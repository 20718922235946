import React, { useState } from 'react';
import classNames from 'classnames';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import styles from './VideoTemplate.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Button from '../Button/Button';
import { getValidUrl } from '../../utils/getValidUrl';
import playCircle from '../../assets/icons/play-circle.svg';
import {templateBackground} from "../../utils/templateBackground";

function VideoTemplate(props) {

	// eslint-disable-next-line no-unused-vars
	const [showQuestions, setShowQuestions] = useState(true); //explicitly called to force re-render (ignore const names)

	const backgroundImage = props.scene.videoFileUrl
		? props.scene.videoFileUrl.slice(0, -4) + '.jpg'
		: templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundImage);

	// function showQuestionAndAnswers() {
	// 	if (!props.showSceneQuestions || !props.scene.showQuestionsOnStart) {
	// 		return styles.hideQuestions;
	// 	}
	// }

	function getSeconds(seconds) {
		if (
			props.scene.showQuestionsOnStart === false &&
			seconds.playedSeconds >= seconds.loadedSeconds &&
			seconds.played === 1
		) {
			props.scene.showQuestionsOnStart = true;

			//We are calling this change state just for a re-render
			setShowQuestions(false);
		} else {
			//We are calling this change state just for a re-render
			setShowQuestions(true);
		}
	}

	function isFirstScene() {
		return props.firstSceneId === props.scene._id;
	}

	function getVideoURL() {
		let VIDEO_QUALITY = 70;
		let videoURL = props.scene.videoFileUrl;
		let videoURLSplited = videoURL.split('/');
		videoURLSplited[5] += '/q_' + VIDEO_QUALITY;
		let videoURLLowQuality = videoURLSplited.join('/');
		return videoURLLowQuality;
	}

	return (
		<React.Fragment>
			{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
				<ChevronLeft
					className={styles.backButton}
					onClick={() => props.goBack()}
					style={{
						fill: props.project?.general?.buttonBackgroundColor?.hex?.hoverBack
							? lightenDarkenColor(props.project?.general?.buttonBackgroundColor?.hex, -40)
							: props.project?.general?.buttonBackgroundColor?.hex,
					}}
				/>
			)}
			{props.showVideo && props.scene.videoFileUrl ? (
				<VideoPlayer
					videoUrl={getVideoURL()}
					backgroundImage={backgroundImage}
					autoplay={props.autoplay}
					getPlaySeconds={getSeconds}
					className={styles.videoPlayer}
					isFirstScene={isFirstScene()}
					sidebar={props.sidebar}
				/>
			) : (
				<div
					className={styles.template}
					style={{
						backgroundImage: backgroundImage && 'url(' + backgroundImage + ')',
					}}
				>
					{props.children}   {/* obsolete with React-Flow */}
				</div>
			)}

			<div className={styles.content}>
				<span className={styles.templateRow}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
						<img className={styles.center} src={playCircle} width="60" style={{ marginBottom: '15px' }} alt="Play Icon" />
					<h1
						className={styles.question}
						style={{ color: props.project?.general?.color?.hex }}
					>
						{props.scene && props.scene.question}
					</h1>
					{props.scene && props.scene.richText && (
						<p
							className={styles.richText}
							dangerouslySetInnerHTML={{ __html: props.scene.richText }}
							style={{ color: props.project?.general?.color?.hex }}
						></p>
					)}
					<div className={styles.buttonsContainer}>
						{props.scene &&
							props.scene.answers.map((answer, index) => (
								<div
									className={classNames(styles.buttonWrapper, {
										[styles.buttonWrapperSmall]: props.scene && props.scene.answers.length > 3,
									})}
									key={index}
								>
									<Button
										key={answer.answer + props.scene._id}
										label={answer.answer}
										secondary
										onClick={() => {
											if (answer.sceneId) {
												props.goTo(answer.sceneId, answer.answer);
											} else if (answer.url) {
												window.open(getValidUrl(answer.url), '_blank');
											}
										}}
										onMouseEnter={() => {
											if (props.setChildIndex) props.setChildIndex(index);
										}}
										onMouseLeave={() => {
											if (props.setChildIndex) props.setChildIndex(null);
										}}
										style={{
											minWidth: props.scene.answers.length === 4 ? '80px' : '',
											color: props.project?.general?.buttonColor?.hex,
											backgroundColor:
												props.project?.general?.buttonBackgroundColor?.hex?.hoverIndex === index
													? lightenDarkenColor(props.project?.general?.buttonBackgroundColor?.hex, -40)
													: props.project?.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							))}
					</div>
				</span>
			</div>
		</React.Fragment>
	);
}

export default VideoTemplate;
