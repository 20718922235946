import { getUserAction } from './userActions';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_RESPONSE = 'AUTH_USER_RESPONSE';
export const LOGOUT_USER = 'LOGOUT_USER';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets auth state with auth data
 * @param {Object} data containing authenticated and jwt token
 * @param {boolean} data.isAuth determining if user is authenticated
 * @param {string} data.token jwt token
 * @returns {void}
 */
export const authUser = (data) => {
	return {
		type: AUTH_USER,
		payload: data,
	};
};

/**
 *
 * @param {string} data containing error
 * @returns {void}
 */
export const setAuthError = (data) => {
	return {
		type: AUTH_USER_ERROR,
		payload: data,
	};
};

export const setAuthResponse = (response) => {
	return {
		type: AUTH_USER_RESPONSE,
		payload: response,
	};
};

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<Object>} user object or undefined
 */
export const fetchLogin = async (email, password) => {
	try {
		const res = await fetch(apiUrl + '/users/login', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({ email: email, password: password }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 *
 * @param {string} email
 * @param {string} password
 * @returns {void}
 */
export const loginUser = (email, password) => {
	return (dispatch) => {
		fetchLogin(email, password).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
				} else {
					localStorage.setItem('token', resJSON.token);
					localStorage.setItem('username', resJSON.user.username);
					localStorage.setItem('id', resJSON.user._id.toString());
					dispatch(authUser({ isAuth: true, token: resJSON.token }));
					dispatch(getUserAction(resJSON.user));
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
			}
		});
	};
};

/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {boolean} acceptedTOS has user accepted Terms of Service
 * @returns {Promise<Object>} user object or undefined
 */
export const fetchRegister = async (username, email, password, acceptedTOS, brandname) => {
	try {
		const res = await fetch(apiUrl + '/users', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({
				username: username,
				email: email,
				password: password,
				acceptedTOS: acceptedTOS,
				belongsTo: brandname,
			}),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {boolean} acceptedTOS has user accepted Terms of Service
 * @returns {Promise<any>} user object or undefined
 */
export const registerUser = (username, email, password, acceptedTOS, brandname) => {
	return (dispatch) => {
		fetchRegister(username, email, password, acceptedTOS, brandname).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
				} else {
					console.log(resJSON);
					dispatch(setAuthResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
			}
		});
	};
};
/**
 *
 * @param {string} email
 */
export const fetchForgotPassword = async (email) => {
	try {
		const res = await fetch(apiUrl + '/users/forgotPassword', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({ email: email }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};
/**
 * Sends email with link to reset password
 * @param {string} email of a user that forgot password
 */
export const sendForgotPasswordEmail = (email) => {
	return (dispatch) => {
		fetchForgotPassword(email).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
				} else {
					dispatch(setAuthResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
			}
		});
	};
};

/**
 *
 * @param {string} email
 */
export const fetchChangePassword = async (token, newPassword) => {
	try {
		const res = await fetch(apiUrl + '/users/changePassword/' + token, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({ password: newPassword }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};
/**
 * Changes user password
 * @param {string} email of a user that forgot password
 */
export const changePassword = (token, newPassword) => {
	return (dispatch) => {
		fetchChangePassword(token, newPassword).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
				} else {
					dispatch(setAuthResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
			}
		});
	};
};

/**
 *
 * @param {string} email
 */
export const fetchVerifyAccount = async (token) => {
	try {
		const res = await fetch(apiUrl + '/users/verifyEmail/' + token, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Verifies account
 * @param {string} token
 */
export const verifyAccount = (token) => {
	return (dispatch) => {
		fetchVerifyAccount(token).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
				} else {
					dispatch(setAuthResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
			}
		});
	};
};

export const fetchLogout = async (token) => {
	try {
		const res = await fetch(apiUrl + '/users/logout', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const logoutUser = (token) => {
	return (dispatch) => {
		fetchLogout(token).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAuthError(resJSON.error));
					dispatch({
						type: 'LOGOUT_USER',
					});
				} else {
					dispatch(setAuthResponse(resJSON.message));
					dispatch({
						type: 'LOGOUT_USER',
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setAuthError(resJSON));
				dispatch({
					type: 'LOGOUT_USER',
				});
			}
		});
	};
};
