import { SET_ACTIVE_TAB } from '../actions/sidebarActions';

const initialState = {
	activeTab: 'add',
};

export default function sidebarReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE_TAB:
			return { ...state, activeTab: action.payload };
		default:
			return state;
	}
}
