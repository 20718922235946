import { AUTH_USER, AUTH_USER_ERROR, AUTH_USER_RESPONSE } from '../actions/authActions';

const initialState = {
	isAuth: false,
	token: '',
	response: '',
	error: '',
};

export default function authReducer(state = initialState, action) {
	if (localStorage.getItem('token')) {
		let token = localStorage.getItem('token');
		state.token = token;
		state.isAuth = true;
	}
	switch (action.type) {
		case AUTH_USER:
			return { ...state, isAuth: action.payload.isAuth, token: action.payload.token };
		case AUTH_USER_ERROR:
			return { ...state, response: '', error: action.payload };
		case AUTH_USER_RESPONSE: {
			return { ...state, response: action.payload, error: '' };
		}
		case 'LOGOUT_USER': {
			return {
				isAuth: false,
				token: '',
				response: '',
				error: '',
			};
		}
		default:
			return state;
	}
}
