import classNames from 'classnames';

import styles from './Button.module.scss';

function Button(props) {
	return (
		<button
			onClick={props.onClick}
			className={classNames(styles.button, {
				[styles.primary]: props.primary,
				[styles.accent]: props.accent,
				[styles.secondary]: props.secondary,
				[styles.disabled]: props.disabled,
				[styles.small]: props.small,
			})}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			type={props.type && props.type}
			style={props.style && props.style}
		>
			{props.icon && <img src={props.icon} alt="buttonIcon" className={styles.icon} />}
			{props.label}
		</button>
	);
}

export default Button;
