import React from 'react';
import styles from './AudioUpload.module.scss';
import upload from '../../assets/icons/upload.svg';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { MAX_AUDIO_UPLOAD_SIZE_BYTES } from '../../utils/constants/maxUploadSize';

const ENVIRONMENT = process.env.REACT_APP_CURRENT_ENV;
const CLOUDINARY_PROD_PRESET = process.env.REACT_APP_CLOUDINARY_PROD_PRESET;
const CLOUDINARY_DEV_PRESET = process.env.REACT_APP_CLOUDINARY_DEV_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

function AudioUpload(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function checkFileExtension(fileName) {
		let fileExtension = fileName.split('.').pop();

		const SUPPORTED_EXTENSION_MP3 = 'mp3';
		const SUPPORTED_EXTENSION_WAV = 'wav';
		const SUPPORTED_EXTENSION_M4A = 'm4a';

		if (
			fileExtension !== SUPPORTED_EXTENSION_MP3 &&
			fileExtension !== SUPPORTED_EXTENSION_WAV &&
			fileExtension !== SUPPORTED_EXTENSION_M4A
		) {
			showPopup(
				`${fileExtension} ${t.audioVoiceover.wrongExtension} ${SUPPORTED_EXTENSION_MP3}, ${SUPPORTED_EXTENSION_WAV} & ${SUPPORTED_EXTENSION_M4A}`
			);
			return false;
		} else {
			return true;
		}
	}

	function handleChange(event) {
		let file = event.target.files[0];

		if (!checkFileExtension(file.name)) return

		props.getAudioName(file.name);
		let reader = new FileReader();

		if (file.size > MAX_AUDIO_UPLOAD_SIZE_BYTES) {
			showPopup(t.audioVoiceover.audioMaxSizeTitle);
		} else {
			reader.addEventListener(
				'load',
				async function () {
					const formData = new FormData();
					//We need to convert our video file to base64 so we can upload it
					const base = await toBase64(file);
					formData.append('file', base);
					if (ENVIRONMENT === 'development') {
						formData.append('upload_preset', CLOUDINARY_DEV_PRESET);
					} else {
						formData.append('upload_preset', CLOUDINARY_PROD_PRESET);
					}
					formData.append('cloud_name', CLOUDINARY_CLOUD_NAME);
					formData.append('resource_type', 'audio');
					/** TODO: make the url underneath to CLOUDINARY_HOST  variable **/
					let cloudinaryUploadURL = 'https://api.cloudinary.com/v1_1/' + CLOUDINARY_CLOUD_NAME + '/upload';
					let requestObj = {
						url: cloudinaryUploadURL,
						method: 'POST',
						data: formData,
						onUploadProgress: function (progressEvent) {
							//Calculate percantage and send it to the parent component (VideoBox)
							props.getProgress((progressEvent.loaded * 100) / progressEvent.total);
						},
					};

					axios(requestObj)
						.then((res) => {
							const url = res.data.secure_url;
							const token = props.token;
							const scene = props.selectedScene;
							const sceneID = scene._id;
							scene.audioFileUrl = url;
							scene.audioFileName = file.name;
							scene.audioFilePublicId = res.data.public_id;
							props.dispatch(props.updateScene(token, sceneID, scene, projectHasDraftScenes));
						})
						.catch((error) => console.log(error));
				},
				false
			);
			if (file && file.name) {
				reader.readAsDataURL(file);
			}
		}
	}

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<React.Fragment>
			<div>
				<div className={styles.uploadBox}>
					<div className={styles.uploadWrapper}>
						<img src={upload} alt="uploadIco" className={styles.uploadIcon} />
						<input
							className={styles.audioInput}
							type="file"
							id="audio"
							name="audio"
							accept=".mp3, .wav, .m4a"
							onChange={(event) => handleChange(event)}
						/>
						<span className={styles.inputText}>{t.audioVoiceover.dragAndDrop}</span>
					</div>
				</div>
				<p className={styles.audioExtensions} style={{ marginTop: '5px' }}>
					{t.audioVoiceover.fileType}
				</p>
				<p className={styles.audioExtensions}>{t.audioVoiceover.maxSize}</p>
			</div>
		</React.Fragment>
	);
}

export default AudioUpload;
