// import { FlowChartWithState } from '@mrblenny/react-flow-chart';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import styled from 'styled-components';
// import { setSelectedTemplate } from '../../actions/addTabActions';
// import { hideModal, showModal } from '../../actions/modalActions';
import { setActiveTab } from '../../actions/sidebarActions';

// import Template from '../../components/Template/Template';
// import { createScene, updateScene, updateAnswer, deleteScenes, copySceneToCanvas } from '../../actions/scenesActions';
// import { getTemplateData } from '../../utils/SceneUtils';
import { useTranslation } from '../../utils/useTranslation';

// import FirstSceneBadgeWithoutArrow from '../../assets/images/FirstSceneBadgeWithoutArrow.png';
// import SceneClickToCanvas from '../../assets/images/SceneClickToCanvas.png';
import { ReactComponent as PlusButton } from '../../assets/icons/plus-button.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as Preview } from '../../assets/icons/preview.svg';
import { ReactComponent as PlayIco } from '../../assets/icons/play.svg';
// import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { checkForFirstScene } from '../../utils/checkForFirstScene';

import Flow from './Flow';
import styles from './Canvas.module.scss';
import Button from "../../components/Button/Button";
import {publishProject} from "../../actions/projectsActions";
import {updateScene} from "../../actions/scenesActions";

const WIDGET_PREVIEW_URL = process.env.REACT_APP_PREVIEW_URL

// TODO: remove console.logs

/**
 * Renders the canvas.
 * Called by ProjectEditor.js
 * @param props.scenesData (project scenes)
 * @param props.setSelectedSceneIds (set currently selected project scenes for editing)
 * @param props.selectedSceneIds (currently selected project scenes for editing)
 * @returns {JSX.Element}
 * @constructor
 */
const Canvas = (props) => {
  const scenes = props.scenesData;
  const history = useHistory();
  const t = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const [key, setKey] = useState(0);
  const [hasUnpublishedChanges, setHasUnpublishedChanges] = useState(false);
  // const flowRef = useRef();
  // const mouseLeft = useRef(true);
  // const dragStopped = useRef(true);
  // const scale = useRef(1);
  // const offset = useRef({ x: 0, y: 0 });
  const token = useSelector((state) => state.auth.token);
  const projects = useSelector((state) => state.projects.data);
  // console.log('projects:', projects)
  const selectedProject =
    projects.length > 0 && projects.filter((project) => project._id.toString() === params.id.toString())[0];
  // const [hoveredScene, setHoveredScene] = useState({});
  // const [isCopy, setIsCopy] = useState(false);
  // const selectedTemplate = useSelector((state) => state.addTab.newScene.templateName);
  // const newSceneByClickOrDrag = useSelector((state) => state.addTab.newScene.clickOrDrag);
  const activeTab = useSelector((state) => state.sidebar.activeTab);
  // const user = useSelector((state) => state.user);
  // const refTemplate = useRef(selectedTemplate);
  // const prevScenes = usePrevious(scenes);

  useEffect(()=>{
    if (scenes.length === 1) {
      dispatch(updateScene(
        token,
        scenes[0]._id,
        { isFirstScene: true },
        selectedProject.hasDraftScenes
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenes.length])

  useEffect(()=>{
    if (selectedProject.hasDraft || selectedProject.hasDraftScenes) setHasUnpublishedChanges(true)
    else setHasUnpublishedChanges(false)
  }, [selectedProject.hasDraft, selectedProject.hasDraftScenes])

  // useEffect(() => {
  // 	dispatch(setSelectedTemplate(null));
  // }, [dispatch]);

  // useEffect(() => {
  // 	refTemplate.current = selectedTemplate;
  // }, [selectedTemplate]);

  // useEffect(() => {

  // flowRef.current.stateActions.onNodeMouseLeave = () => {
  // 	mouseLeft.current = true;
  // };
  // flowRef.current.stateActions.onNodeMouseEnter = (e) => {
  // 	mouseLeft.current = false;
  // };

  // flowRef.current.stateActions.onCanvasClick = (e) => {
  // 	if (mouseLeft.current === true && dragStopped.current === true) {
  // 		props.setSelectedSceneId('');
  // 	}
  // 	/**
  // 	 * (mouseX - canvas offsetX) * (scale^-1)
  // 	 */
  // 	const xAdjust = 80 * Math.pow(scale.current, 0.5) // move new scene left a little
  // 	const deltaX = (e.clientX - offset.current.x - xAdjust) / scale.current;
  // 	/**
  // 	 * (mouseY - canvas offsetY) * (scale^-1) - (sceneHeight/2 / scale)
  // 	 */
  // 	const yAdjust = 150 * Math.pow(scale.current, 0.3) // move new scene up a little
  // 	const deltaY = (e.clientY - offset.current.y - yAdjust) / scale.current;
  // 	if (refTemplate.current) {
  // 		dispatch(
  // 			createScene(
  // 				getTemplateData(token, params.id, refTemplate.current || 'text', {
  // 					scenesLength: scenes.length,
  // 					deltaX: deltaX,
  // 					deltaY: deltaY,
  // 					email: user.email,
  // 				})
  // 			)
  // 		);
  // 		dispatch(setSelectedTemplate(null));
  // 	}
  // 	if (isCopy) {
  // 		dispatch(copySceneToCanvas(token, props.selectedSceneId, { x: deltaX, y: deltaY }));
  // 		setIsCopy(false);
  // 	}
  // };

  // flowRef.current.stateActions.onCanvasDrop = (e) => {
  // 	if (mouseLeft.current === true && dragStopped.current === true) {
  // 		props.setSelectedSceneId('');
  // 	}
  // 	const xAdjust = 120 // move new scene left a little
  // 	const deltaX = e.position.x - xAdjust
  // 	const yAdjust = 70 // move new scene up a little
  // 	const deltaY = e.position.y - yAdjust
  // 	if (refTemplate.current) {
  // 		dispatch(
  // 			createScene(
  // 				getTemplateData(token, params.id, refTemplate.current || 'text', {
  // 					scenesLength: scenes.length,
  // 					deltaX: deltaX,
  // 					deltaY: deltaY,
  // 					email: user.email,
  // 				})
  // 			)
  // 		);
  // 		dispatch(setSelectedTemplate(null));
  // 	}
  // };

  // let backupOnZoomcanvas = flowRef.current.stateActions.onZoomCanvas;
  // flowRef.current.stateActions.onZoomCanvas = (e) => {
  // 	backupOnZoomcanvas(e);
  // 	scale.current = e.data.scale;
  // 	offset.current = {
  // 		x: e.data.positionX,
  // 		y: e.data.positionY,
  // 	};
  // };
  // flowRef.current.stateActions.onDragCanvasStart = () => {
  // 	dragStopped.current = false;
  // };
  // flowRef.current.stateActions.onDragCanvasStop = (e) => {
  // 	dragStopped.current = true;
  // 	scale.current = e.data.scale;
  // 	offset.current = {
  // 		x: e.data.positionX,
  // 		y: e.data.positionY,
  // 	};
  // };
  //let linkComplete = flowRef.current.stateActions.onLinkComplete;
  // flowRef.current.stateActions.onLinkComplete = (e) => {
  // 	if (e.fromNodeId === e.toNodeId) return;
  // 	//linkComplete(e);
  // 	//is link backwards?
  // 	if (e.fromPortId === 'portInput') {
  // 		let fromPortId = e.toPortId;
  // 		let fromNodeId = e.toNodeId;
  // 		let toNodeId = e.fromNodeId;
  // 		if (fromPortId.match(/\d+/))
  // 			dispatch(updateAnswer(token, fromNodeId, Number(fromPortId.match(/\d+/)[0]), { sceneId: toNodeId }));
  // 	} else {
  // 		dispatch(updateAnswer(token, e.fromNodeId, Number(e.fromPortId.match(/\d+/)[0]), { sceneId: e.toNodeId }));
  // 	}
  // };
  // flowRef.current.stateActions.onDragNodeStop = (e) => {
  // 	let position = getNodeState(e.id).position;
  // 	dispatch(updateScene(token, e.id, { position }));
  // 	//dispatch(saveToStorage(e.id, 'position', position));
  // };

  // 	let canvas = document.getElementsByClassName('react-transform-component')[0];
  // 	if (canvas) canvas.style.width = '-moz-max-content';
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props, key, dispatch, token, params, scenes.length, selectedTemplate]);

  // const findSceneById = useCallback(
  // 	(id) => {
  // 		for (let scene of scenes) {
  // 			if (scene._id.toString() === id.toString()) {
  // 				return scene;
  // 			}
  // 		}
  // 	},
  // 	[scenes]
  // );

  // const links =
  // 	scenes.length > 0 &&
  // 	scenes.map((scene) => {
  // 		return scene.answers; //count scene ids in every answer
  // 	}).length;

  useEffect(() => {
    setKey(Math.round(Math.random() * 10000));
  }, [scenes.length, props.sceneKey]);
  // useEffect(() => {
  // 	setKey(Math.round(Math.random() * 10000));
  // }, [scenes.length, links, props.sceneKey]);

  /**
   * TODO: Check why this is needed and document this?? @Franjo & @Jasmin
   * it is breaking the app when clicking "back arrow" form the preview screen
   */
  // useEffect(() => {
  // 	if (scenes && scenes.length && scenes.length > prevScenes.length) {
  // 		props.setSelectedSceneId(scenes[scenes.length - 1]._id);
  // 	}
  // }, [scenes.length]);

  // function dispatchCopyScene(position) {
  // 	console.log('props.selectedSceneId for copying:', props.selectedSceneId)
  // 	console.log('position for copying:', position)
  // 	dispatch(copySceneToCanvas(token, props.selectedSceneId, { x: position.x, y: position.y }));
  // }

  // function dispatchDeleteScene() {
  // 	dispatch(
  // 		showModal('POPUP_MODAL', {
  // 			title: t.sceneTab.confirmDelete,
  // 			buttonPrimary: {
  // 				label: t.popup.yes,
  // 				action: () => {
  // 					console.log('props.selectedSceneId for deletion:', props.selectedSceneId)
  // 					dispatch(deleteScene(token, props.selectedSceneId));
  // 					props.setSelectedSceneId('');
  // 					dispatch(hideModal());
  // 					const scenesFilterArray = scenes.filter((s) => s._id === props.selectedSceneId);
  // 					const currentScene = scenesFilterArray[0];
  // 					console.log('currentScene:', currentScene)
  // 					currentScene.isFirstScene &&
  // 					dispatch(
  // 						showModal('POPUP_MODAL', {
  // 							title: t.preview.chooseStartScene,
  // 							buttonPrimary: {
  // 								label: t.popup.ok,
  // 								action: () => {
  // 									dispatch(hideModal());
  // 								},
  // 							},
  // 						})
  // 					);
  // 				},
  // 			},
  // 			buttonSecondary: {
  // 				label: t.popup.no,
  // 				action: () => {
  // 					dispatch(hideModal());
  // 				},
  // 			},
  // 		})
  // 	);
  // }

  /**
   * Positions scenes on canvas and creates connections depending on scene.answers state
   * It preserves zoom and canvas dragging offset
   * Is called on every scene redux state update
   */
  // const createChart = () => {
  // 	let chart = { offset: offset.current, nodes: {}, links: {}, selected: {}, hovered: {}, scale: scale.current };
  // 	if (scenes.length === 0) return chart;
  // 	let scenesSorted = scenes.sort((a, b) => {
  // 		return new Date(a.created) - new Date(b.created);
  // 	});
  // 	const paramsOrScenePos = (x, y, scene) => {
  // 		if (scene.position && scene.position.x && scene.position.y) {
  // 			return scene.position;
  // 		} else {
  // 			return { x: x, y: y };
  // 		}
  // 	};
  // 	let i = 1;
  // 	for (let scene of scenesSorted) {
  // 		let node = { ports: {} };
  // 		node.templateType = scene.template;
  // 		node.id = scene._id;
  // 		node.position = paramsOrScenePos(400, i * 300, scene);
  // 		node.type = 'input-output';
  // 		chart.nodes[scene._id] = node;
  // 		let j = 0;
  // 		if (scene.answers.length === 0) {
  // 			chart.nodes[scene._id]['ports']['portInput'] = {
  // 				id: 'portInput',
  // 				type: 'input',
  // 			};
  // 		}
  // 		for (let answer of scene.answers) {
  // 			let port = {
  // 				id: 'port' + j,
  // 				type: 'output',
  // 			};
  // 			chart.nodes[scene._id]['ports']['port' + j] = port;
  // 			chart.nodes[scene._id]['ports']['portInput'] = {
  // 				id: 'portInput',
  // 				type: 'input',
  // 			};

  // 			//chack if button is connected to the scene
  // 			if (answer.sceneId) {
  // 				const foundScene = findSceneById(answer.sceneId)?._id;
  // 				let link = {
  // 					id: 'link' + j.toString() + i.toString(),
  // 					from: {
  // 						nodeId: scene._id,
  // 						portId: 'port' + j,
  // 					},
  // 					to: {
  // 						nodeId: foundScene,

  // 						portId: 'portInput',
  // 					},
  // 				};
  // 				chart['links']['link' + j.toString() + i.toString()] = link;
  // 			}
  // 			j++;
  // 		}
  // 		i++;
  // 	}
  // 	const linkKeys = Object.keys(chart.links);
  // 	const parentChild = {};
  // 	//find all parent-child pairs
  // 	for (let key of linkKeys) {
  // 		let link = chart.links[key];
  // 		let fromNodeId = link.from.nodeId;
  // 		let toNodeId = link.to.nodeId;
  // 		if (Array.isArray(parentChild[fromNodeId])) {
  // 			parentChild[fromNodeId].push(toNodeId);
  // 		} else {
  // 			parentChild[fromNodeId] = [toNodeId];
  // 		}
  // 	}
  // 	//fill in parents without children
  // 	for (let key of Object.keys(chart.nodes)) {
  // 		if (!parentChild[key]) {
  // 			parentChild[key] = [];
  // 		}
  // 	}
  // 	return chart;
  // };

  // const getNodeState = (id) => {
  // 	return flowRef.current.state.nodes[id];
  // };

  // const TextOnly = styled.div``;

  // const customNode = forwardRef(({ node, children, ...otherProps }, ref) => {
  // 	let scene = findSceneById(node.id);
  // 	if (scene)
  // 		return (
  // 			<TextOnly
  // 				ref={ref}
  // 				{...otherProps}
  // 				className={classNames(styles.scene, {
  // 					[styles.selected]: props.selectedSceneId === node.id || hoveredScene === node.id,
  // 				})}
  // 				onClick={() => {
  // 					props.setSelectedSceneId(node.id);
  // 					let position = getNodeState(node.id).position;
  // 					dispatch(updateScene(token, node.id, { position }));
  // 				}}
  // 			>
  // 				<span
  // 					onClick={() => {
  // 						setIsCopy(true);
  // 					}}
  // 					className={styles.copyButton}
  // 				>
  // 					<CopyIcon className={styles.copyIcon} />
  // 				</span>
  // 				<span
  // 					onClick={() => dispatchDeleteScene() }
  // 					className={styles.deleteButton}
  // 				>
  // 					X
  // 				</span>
  // 				<Template
  // 					scene={scene}
  // 					children={children}
  // 					goTo={() => {}}
  // 					templateType={scene.template}
  // 					project={selectedProject}
  // 					setChildIndex={(index) => {
  // 						let answ = scene.answers[index];
  // 						if (answ && answ.sceneId) {
  // 							setHoveredScene(answ.sceneId);
  // 						} else {
  // 							setHoveredScene({});
  // 						}
  // 					}}
  // 					showAudioplayer={false}
  // 					showVideo={false}
  // 					autoplay={false}
  // 					showSceneQuestions={true}
  // 					sidebar={true}
  // 				/>
  // 			</TextOnly>
  // 		);
  // 	else {
  // 		return (
  // 			<TextOnly ref={ref} {...otherProps} className={styles.scene}>
  // 				<span />
  // 			</TextOnly>
  // 		);
  // 	}
  // });

  console.log('canvas render with selectedSceneIds:', props.selectedSceneIds)
  return (
    <React.Fragment>
      {/* <div className={classNames(styles.canvas, (isCopy || selectedTemplate) && styles.isCreating)} key={key}> */}
      <div className={styles.canvas} key={key}>
        <h1
          className={styles.projectTitle}
          onClick={() => {
            history.push('/projectList');
          }}
        >
          <ChevronLeft className={styles.ico} />
          {selectedProject.title}
        </h1>
        <Flow
          // ref={flowRef}
          scenes={scenes}
          setSelectedSceneIds={props.setSelectedSceneIds}
          selectedSceneIds={props.selectedSceneIds}
          // copyScene={dispatchCopyScene}
          // deleteScene={dispatchDeleteScene}
          project={selectedProject}
          // setIsCopy={setIsCopy} // change to copy cursor
          // token={token}
        />
        {/*<FlowChartWithState*/}
        {/*	ref={flowRef}*/}
        {/*	initialValue={createChart()}*/}
        {/*	config={{ readonly: false, selectable: true, smartRouting: false }}*/}
        {/*	Components={{*/}
        {/*		Node: customNode,*/}
        {/*	}}*/}
        {/*/>*/}
      </div>
      {/* {scenes && scenes.length === 0 && !selectedTemplate && activeTab === 'add' && (
				<>
					<img
						style={{ maxWidth: '300px' }}
						src={FirstSceneBadgeWithoutArrow}
						className={styles.firstSceneBadge}
						alt="First scene badge"
					/>
				</>
			)} */}
      {/* {selectedTemplate && newSceneByClickOrDrag === 'click' && (
				<img src={SceneClickToCanvas} className={styles.sceneClickBadge} alt="Scene Click to Canvas" />
			)} */}
      {/* {!selectedTemplate && activeTab !== 'add' && ( */}
      {activeTab !== 'add' && (
        <PlusButton
          className={classNames(styles.plusButton)}
          alt="Create Scene button"
          onClick={() => dispatch(setActiveTab('add'))}
        />
      )}
      <div className={styles.publishBtn}>
        <Button
          primary={hasUnpublishedChanges}
          disabled={!hasUnpublishedChanges}
          label={t.canvas.publish}
          onClick={() => dispatch(publishProject(token, selectedProject._id))}
        />
      </div>
      <Preview
        className={classNames({ [styles.previewBtnEnabled]: hasUnpublishedChanges,  [styles.previewBtnDisabled]: !hasUnpublishedChanges})}
        alt="Preview button"
        onClick={async () => {  // previously: history.push('/preview/' + selectedProject._id)
          const check = await checkForFirstScene(scenes, dispatch, t);
          check && window.open(`${WIDGET_PREVIEW_URL}/berater/${selectedProject._id}?draft=true`, '_blank');
        }}
      />
      <PlayIco
        className={styles.playButton}
        alt="Play placeholder"
        onClick={async () => {  // previously: history.push('/preview/' + selectedProject._id)
          const check = await checkForFirstScene(scenes, dispatch, t);
          check && window.open(`${WIDGET_PREVIEW_URL}/berater/${selectedProject._id}`, '_blank');
        }}
      />
    </React.Fragment>
  );
};

export default Canvas;
