import { store } from '../index';

export function ifUserRole(...roles) {
	let currentUserRole = store?.getState()?.user?.role;
	return roles.some((role) => role === currentUserRole);
}

export function isAuth() {
	return store.getState().auth.isAuth;
}
