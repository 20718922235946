import { GET_USER, UPDATE_USER, DELETE_USER, USER_ERROR, USER_RESPONSE } from '../actions/userActions';

const initialState = {
	isVerified: '',
	acceptedTOS: '',
	_id: '',
	username: '',
	email: '',
	password: '',
	tokens: '',
	updated: '',
	created: '',
	__v: '',
	trialExpirationDate: '',
	response: '',
	error: '',
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USER:
			return action.payload;
		case UPDATE_USER:
			return { ...state, ...action.payload };
		case DELETE_USER:
			return initialState;
		case USER_ERROR:
			return { ...state, response: '', error: action.payload };
		case USER_RESPONSE: {
			return { ...state, response: action.payload, error: '' };
		}
		case 'LOGOUT_USER': {
			return initialState;
		}
		default:
			return state;
	}
}
