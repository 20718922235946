import { GET_INQUIRIES, CAN_ACCESS, INQUIRIES_ERROR } from '../actions/inquiriesActions';

const initialState = [];

export default function inquiriesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INQUIRIES:
			return action.payload;
		case CAN_ACCESS:
			return { ...state, inquiriesData: action.payload };
		case INQUIRIES_ERROR:
			return initialState;
		default:
			return state;
	}
}
