import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { getUser } from '../actions/userActions';

function PrivateRoute({ children, ...rest }) {
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUser(auth.token));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth.isAuth ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
