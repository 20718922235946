import React from 'react';

import styles from './SidebarBox.module.scss';

function SidebarBox(props) {
	return (
		<React.Fragment>
			{/* <hr className={styles.hr} /> */}
			<div className={styles.box}>{props.children}</div>
		</React.Fragment>
	);
}

export default SidebarBox;
