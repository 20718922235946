import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-circle.svg';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-mini.svg';

import SidebarBox from '../../components/SidebarBox/SidebarBox';
import EditContactField from '../EditContactField/EditContactField';
import Input from '../Input/Input';
import Answer from '../../components/Answer/Answer';
import { updateSceneAnswer, updateAnswer, removeAnswer } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';
import RichText from '../RichText/RichText';

import styles from './ContactProBox.module.scss';
import {useSelector} from "react-redux";

function ContactProBox(props) {
	const t = useTranslation();
	const [addFieldForm, setAddFieldForm] = useState(false);
	const [editFieldForm, setEditFieldForm] = useState({});
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	const submitField = (field, index) => {
		const newContactPro = props.selectedScene.contactPro;
		const updatedFields = newContactPro.fields || [];
		updatedFields[index] = field;
		newContactPro.fields = updatedFields;
		props.dispatch(
			props.updateScene(props.token, props.selectedScene._id, { contactPro: newContactPro }, projectHasDraftScenes)
		);
		setAddFieldForm(false);
		setEditFieldForm({});
	}

	const deleteField = (index) => {
		const newContactPro = props.selectedScene.contactPro;
		const updatedFields = newContactPro.fields || [];
		updatedFields.splice(index, 1)
		newContactPro.fields = updatedFields;
		props.dispatch(
			props.updateScene(props.token, props.selectedScene._id, { contactPro: newContactPro }, projectHasDraftScenes)
		);
	}

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;  // if user drags outside of DragDropContext, do nothing
		const fields = [...props.selectedScene.contactPro?.fields];
		const [reorderedField] = fields.splice(result.source.index, 1);
		fields.splice(result.destination.index, 0, reorderedField);
		props.dispatch(
			props.updateSceneProp(props.selectedScene._id, 'contactPro', {
				...props.selectedScene.contactPro,
				fields: fields,
			})
		);
	}

	if(!props.selectedScene.contactPro) {  // add contactPro to old scenes without it
		props.dispatch(
			props.updateScene(props.token, props.selectedScene._id, { contactPro: { emailLink: ''} }, projectHasDraftScenes)
		);
		return null;
	} else {
		return (
			<SidebarBox>
				{/* temporary style copied from ImageTextBox */}
				<div className={styles.inputGroupDescription}>
					<label className={styles.label}>{t.sceneTab.description}</label>
					<RichText
						selectedScene={props.selectedScene}
						onChange={(e) => {
							props.dispatch(
								props.updateSceneProp(props.selectedScene._id, 'contactPro', {
									...props.selectedScene.contactPro,
									description: e,
								})
							);
						}}
						onBlur={() => {
							props.dispatch(
								props.updateScene(
									props.token,
									props.selectedScene._id,
									{ contactPro: props.selectedScene.contactPro },
									projectHasDraftScenes
								)
							);
						}}
						defaultValue={props.selectedScene.contactPro?.description || t.sceneTab.description}
					/>
				</div>

				<div className={styles.box}>
					<label className={styles.label}>{t.sceneTab.contactSection.contactFields}</label>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="proFields">
    					{(provided) => (<div {...provided.droppableProps} ref={provided.innerRef}>
								{props.selectedScene.contactPro?.fields && props.selectedScene.contactPro.fields.length > 0 &&
									props.selectedScene.contactPro.fields.map((field, idx) => {
										return (
											<Draggable key={field.name + idx} draggableId={field.name} index={idx}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
														<Row className='mx-1'>
															<Col xs={6}>
																<div className={styles.label}>{field.name}</div>
															</Col>
															<Col>
																<PenIcon
																	alt="PenIcon"
																	className={styles.icon}
																	onClick={() => setEditFieldForm({[idx]: true})}
																/>
															</Col>
															<Col>
																<MinusIcon
																	className={styles.minusIcon}
																	onClick={() => deleteField(idx)}
																/>
															</Col>
														</Row>
														{editFieldForm[idx] &&
															<EditContactField
																selectedScene={props.selectedScene}
																submitField={submitField}
																currentField={field}
																fieldIndex={idx}
															/>
														}
													</div>
												)}
											</Draggable>)
								})}
								{provided.placeholder}
							</div>)}
						</Droppable>
					</DragDropContext>
					<div className={styles.addFieldInputGroup}>
						<label className={styles.addFieldLabel}>{t.sceneTab.addContactField}</label>
						<PlusIcon className={styles.plusIcon} onClick={() => setAddFieldForm(true)} />
					</div>
					{addFieldForm &&
						<EditContactField
							selectedScene={props.selectedScene}
							submitField={submitField}
							fieldIndex={props.selectedScene.contactPro?.fields ? props.selectedScene.contactPro.fields.length : 0}
						/>
					}

					<div>
						<label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.email}:</label>
						<Input
							type="text"
							name="contactPro"
							value={props.selectedScene.contactPro.emailLink}
							onChange={(e) =>
								props.dispatch(
									props.updateSceneProp(props.selectedScene._id, e.target.name, {
										...props.selectedScene.contactPro,
										emailLink: e.target.value,
									})
								)
							}
							onBlur={() => {
								if (
									props.selectedScene.contactPro.emailLink.length === 0 ||
									validateEmail(props.selectedScene.contactPro.emailLink)
								) {
									props.dispatch(
										props.updateScene(
											props.token,
											props.selectedScene._id,
											{ contactPro: props.selectedScene.contactPro },
											projectHasDraftScenes
										)
									);
								}
							}}
							ternary
							error={
								props.selectedScene &&
								props.selectedScene?.contactPro.emailLink &&
								props.selectedScene.contactPro?.emailLink.length > 0 &&
								!validateEmail(props.selectedScene.contactPro.emailLink) &&
								t.sceneTab.errors.email
							}
						/>
					</div>

					<div>
						<label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.ccEmail}:</label>
						<Input
							type="text"
							name="contactPro"
							placeholder={props.selectedScene.contactPro.ccEmail || t.sceneTab.contactSection.ccEmailPlaceholder}
							value={props.selectedScene.contactPro.ccEmail}
							onChange={(e) =>
								props.dispatch(
									props.updateSceneProp(props.selectedScene._id, e.target.name, {
										...props.selectedScene.contactPro,
										ccEmail: e.target.value,
									})
								)
							}
							onBlur={() => {
								if (
									(props.selectedScene.contactPro.ccEmail && props.selectedScene.contactPro.ccEmail.length === 0) ||
									validateEmail(props.selectedScene.contactPro.ccEmail)
								) {
									props.dispatch(
										props.updateScene(
											props.token,
											props.selectedScene._id,
											{ contactPro: props.selectedScene.contactPro },
											projectHasDraftScenes
										)
									);
								}
							}}
							ternary
							error={
								props.selectedScene &&
								props.selectedScene?.contactPro.ccEmail &&
								props.selectedScene.contactPro?.ccEmail.length > 0 &&
								!validateEmail(props.selectedScene.contactPro.ccEmail) &&
								t.sceneTab.errors.email
							}
						/>
					</div>
					<div className={styles.inputGroup}>
						<Answer
							key={0}
							index={0}
							answer={props.selectedScene.answers[0]}
							scenes={props.scenes}
							selectedScene={props.selectedScene}
							token={props.token}
							dispatch={props.dispatch}
							updateSceneAnswer={updateSceneAnswer}
							updateAnswer={updateAnswer}
							removeAnswer={removeAnswer}
							contact
						/>
					</div>
				</div>
			</SidebarBox>
		);
	}
}

export default ContactProBox;
