import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GeneralBox from '../../components/GeneralBox/GeneralBox';
import ImageBox from '../../components/ImageBox/ImageBox';
import ColorBox from '../../components/ColorBox/ColorBox';
import CustomCssBox from '../../components/CustomCSSBox/CustomCSSBox';
import FooterBox from '../../components/FooterBox/FooterBox';
import { updateProject, updateProjectProp, deleteProject } from '../../actions/projectsActions';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import ChangeOwnerBox from '../../components/ChangeOwnerBox/ChangeOwnerBox';
import SelectInput from "../../components/SelectInput/SelectInput";
import {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from "../../utils/constants/languages";
import styles from "../../containers/GeneralTab/GeneralTab.module.scss";

// called by Sidebar.js
function GeneralTab(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const token = useSelector((state) => state.auth.token);
	const projects = useSelector((state) => state.projects);
	const selectedProject = projects.data.filter((project) => project._id === props.projectId)[0];
	let userLanguage = useSelector((state) => state.user.language);
	const [warning, setWarning] = useState(false);
	const [hasUnpublishedChanges, setHasUnpublishedChanges] = useState(false);

	if (!userLanguage) userLanguage = DEFAULT_LANGUAGE;

	useEffect(()=>{
		if (selectedProject.hasDraft || selectedProject.hasDraftScenes) setHasUnpublishedChanges(true)
		else setHasUnpublishedChanges(false)
	}, [selectedProject.hasDraft, selectedProject.hasDraftScenes])

	const getLanguageOptions = () => {
		const options = SUPPORTED_LANGUAGES.map( lang => {
			return {
				value: lang,
				label: lang,
				onClick: () => dispatch(updateProject(token, {...selectedProject, language: lang})),
			}
		})
		return options
	}

	function onDeleteProject() {
		if (hasUnpublishedChanges) {
			dispatch(
				showModal('POPUP_MODAL', {
					title: t.projectList.publishBeforeDelete,
					buttonPrimary: {
						label: t.popup.ok,
						action: () => dispatch(hideModal())
					},
				})
			);
		} else {
			dispatch(
				showModal('POPUP_MODAL', {
					title: t.projectList.confirmDelete,
					buttonPrimary: {
						label: t.popup.yes,
						action: () => {
							dispatch(deleteProject(token, selectedProject._id));
							history.push('/projectList');
							dispatch(hideModal());
						},
					},
					buttonSecondary: {
						label: t.popup.no,
						action: () => {
							dispatch(hideModal());
						},
					},
				})
			);
		}
	}

	return (
		<React.Fragment>
			<GeneralBox
				selectedProject={selectedProject}
				updateProjectProp={(e) => {
					dispatch(updateProjectProp(selectedProject._id, e.target.name, e.target.value));
				}}
				updateProject={() => dispatch(updateProject(token, selectedProject))}
			/>

			{ifUserRole(ROLES.ADMIN) && <ChangeOwnerBox project={selectedProject} />}

			<div className={styles.selectLang}>
				<label className={styles.changeLangLabel}>{t.generalTab.customerLanguage}:</label>
				<SelectInput
					options={getLanguageOptions()}
					selected={selectedProject.language ? selectedProject.language : userLanguage}
					ternary
					noScroll
				/>
			</div>

			<ImageBox
				selectedProject={selectedProject}
				label={t.generalTab.previewImage}
				updateProject={updateProject}
				token={token}
				index={0}
			/>

			{ifUserRole(ROLES.ADMIN) && (
				<CustomCssBox
					dispatch={dispatch}
					scope={'project'}
					updateSceneOrProject={updateProject}
					token={token}
					selectedSceneOrProject={selectedProject}
					updateSceneOrProjectProp={updateProjectProp}
				/>
			)}

			<ColorBox
				token={token}
				selectedProject={selectedProject}
				dispatch={dispatch}
				updateProject={updateProject}
			/>

			<FooterBox
				linkLabel={t.generalTab.delete}
				onLinkClick={onDeleteProject}
				buttonLabel={t.generalTab.save}
				onButtonClick={() => {
					dispatch(updateProject(token, selectedProject));
					setWarning(true);
				}}
				warning={warning && projects.response}
			/>
		</React.Fragment>
	);
}

export default GeneralTab;
