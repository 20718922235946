import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { loginUser, setAuthError, setAuthResponse } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';

import styles from './Login.module.scss';
import logo from '../../assets/icons/logo.svg';
import mailIcon from '../../assets/icons/mail.svg';
import passwordIcon from '../../assets/icons/password.svg';

function Login() {
	const auth = useSelector((state) => state.auth);
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	useEffect(() => {
		setEmailError('');
		dispatch(setAuthError(''));
	}, [dispatch, email]);

	useEffect(() => {
		setPasswordError('');
		dispatch(setAuthError(''));
	}, [dispatch, password]);

	useEffect(() => {
		dispatch(setAuthResponse(''));
		dispatch(setAuthError(''));
	}, [dispatch]);

	useEffect(() => {
		if (auth.isAuth) {
			history.push('/projectList');
		}
	}, [auth.isAuth, history]);

	function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		if (email.length === 0) {
			error = true;
			setEmailError(t.login.fieldRequired);
		} else if (!validateEmail(email)) {
			error = true;
			setEmailError(t.login.emailInvalid);
		}
		if (password.length === 0) {
			error = true;
			setPasswordError(t.login.fieldRequired);
		}
		if (!error) {
			dispatch(loginUser(email, password));
		}
	}

	function renderWarning() {
		if (auth.error !== '') {
			return auth.error;
		}
		if (auth.response !== '') {
			return auth.response;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<h1 className={styles.title}>Willkommen!</h1>
					<h2 className={styles.subtitle}>
						{t.login.get14Days} <br />
						{t.login.freeAccess}
					</h2>
					<div className={styles.buttonWrapper}>
						<Button
							label={t.login.ensureTestAccess}
							onClick={() => {
								history.push('/register');
							}}
							primary
							accent
						/>
					</div>
				</div>
			</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img src={logo} className={styles.logo} alt="logo" />
				<div className={styles.inputWrapper}>
					<Input
						type="text"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={emailError}
						placeholder={t.login.emailPlaceholder}
						icon={mailIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={passwordError}
						placeholder={t.login.passwordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={classNames(styles.warning, { [styles.success]: auth.response !== '' })}>
					{renderWarning()}
				</div>
				<div className={styles.submitWrapper}>
					<Button label={t.login.loginButtonLabel} type="submit" primary />
				</div>
				<Link to="/forgotPassword" className={styles.link}>
					{t.login.forgotPasswordLink}
				</Link>
				<Link to="/register" className={classNames(styles.link, styles.mobileLink)}>
					{t.register.registerButtonLabel}
				</Link>
			</form>
		</div>
	);
}

export default Login;
