import {
	GET_PROJECTS,
	CREATE_PROJECT,
	COPY_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
	PROJECT_ERROR,
	PROJECT_RESPONSE,
	UPDATE_PROJECT_PROP,
	SET_ADMIN_VIEW,
	INCREASE_VIEW_COUNT,
} from '../actions/projectsActions';

/* const project = { title: '', description: '', updated: '', created: '' }; */

const initialState = {
	data: [],
	response: '',
	error: '',
	admin: false,
};
//TODO: Check if this is still used
export default function projectReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PROJECTS:
			return { ...state, data: action.payload };
		case CREATE_PROJECT:
			return { ...state, data: state.data.concat(action.payload) };
		case COPY_PROJECT:
			return { ...state, data: state.data.concat(action.payload) };
		case UPDATE_PROJECT: {
			return {
				...state,
				data: state.data.map((project) => {
					if (project._id === action.payload._id) {
						return action.payload;
					} else {
						return project;
					}
				}),
			};
		}
		case DELETE_PROJECT: {
			return { ...state, data: state.data.filter((project) => project._id !== action.payload) };
		}
		case PROJECT_ERROR:
			return { ...state, response: '', error: action.payload };
		case PROJECT_RESPONSE: {
			return { ...state, response: action.payload, error: '' };
		}
		case 'LOGOUT_USER': {
			return initialState;
		}
		case UPDATE_PROJECT_PROP:
			return {
				...state,
				data: state.data.map((project) => {
					if (project._id === action.projectId) {
						project[action.name] = action.value;
					}
					return project;
				}),
			};
		case SET_ADMIN_VIEW:
			return {
				...state,
				admin: action.payload,
			};
		case INCREASE_VIEW_COUNT:
			return {
				...state,
				data: state.data.map((project) => {
					if (project.id === action.payload.projectId) {
						project.views = project.views + action.payload.increment;
					}
					return project
				}),
			};
		default:
			return state;
	}
}
