import {
	GET_SCENES,
	CAN_ACCESS,
	CREATE_SCENE,
	UPDATE_SCENE,
	DELETE_SCENE,
	SCENE_ERROR,
	SCENE_RESPONSE,
	UPDATE_SCENE_PROP,
	ADD_SCENE_ANSWER,
	UPDATE_SCENE_ANSWER,
	UPDATE_SCENE_CONTACT,
} from '../actions/scenesActions';

const initialState = {
	data: [],
	response: '',
	error: '',
	key: 1,
};

export default function sceneReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SCENES:
			return { ...state, data: action.payload };
		case CAN_ACCESS:
			return { ...state, data: action.payload };
		case CREATE_SCENE: {
			return {
				...state,
				data: state.data.concat(action.payload),
			};
		}
		case UPDATE_SCENE: {
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.payload._id) {
						return action.payload;
					} else {
						return item;
					}
				}),
				key: action.key || action.payload.key || state.key,
			};
		}
		case DELETE_SCENE: {
			return { ...state, data: action.payload };
		}
		case SCENE_ERROR:
			return { ...state, response: '', error: action.payload };
		case SCENE_RESPONSE: {
			return { ...state, response: action.payload, error: '' };
		}
		case 'LOGOUT_USER': {
			return initialState;
		}
		case UPDATE_SCENE_PROP:
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.sceneId) {
						item[action.name] = action.value;
					}
					return item;
				}),
			};
		case ADD_SCENE_ANSWER:
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.sceneId) {
						item.answers.push(action.answer);
					}
					return item;
				}),
			};
		case UPDATE_SCENE_ANSWER:
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.sceneId) {
						item.answers.map((answer, index) => {
							if (index === action.answerIndex) {
								answer[action.name] = action.value;
							}
							return answer;
						});
					}
					return item;
				}),
				key: action.key,
			};
		case UPDATE_SCENE_CONTACT:
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.sceneId) {
						item.contact.map((contact, index) => {
							/* if (index === action.answerIndex) {
								answer[action.name] = action.value;
							} */
							return contact;
						});
					}
					return item;
				}),
				key: action.key,
			};
		default:
			return state;
	}
}
