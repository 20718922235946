/* Calls fetchDeleteImage() to make BE API call to delete image
  from Cloudinary and updates the project or scene in Redux and BE.
  Called by ImageBox.js for deleting the project preview image,
  by ImageTextBox.js for deleting the 'text_image' template image and
  by Answer.js for deleting the 'images' and 'columns' template images.
 */

import { fetchDeleteImage } from "../actions/scenesActions";

export	const deleteImage = async (token, item, selectedItem, updateItem, dispatch, answerIndex) => {
  switch (item) {
    case 'project_preview':
      if (!selectedItem.general.previewImage) return;
      await fetchDeleteImage(token, selectedItem.general.previewImage);
      dispatch(
        updateItem(token, {
          ...selectedItem,
          general: {
            ...selectedItem.general,
              previewImage: '',
              imagePublicId: '',
              retrieveOrigSize: false
          },
        }));
      break;
    case 'text_image':
      if (!selectedItem.imageUrl) return;
      await fetchDeleteImage(token, selectedItem.imageUrl);
      dispatch(
        updateItem(token, selectedItem._id, {
          imageDescription: '',
          imagePublicId: '',
          imageUrl: '',
          retrieveOrigSize: false
        }));
      break;
    case 'images_or_columns':
      if (!selectedItem.answers[answerIndex].imageUrl) return;
      await fetchDeleteImage(token, selectedItem.answers[answerIndex].imageUrl);
      dispatch(
        updateItem(token, selectedItem._id, answerIndex, {
          imageDescription: '',
          imagePublicId: '',
          imageUrl: '',
          retrieveOrigSize: false
        }));
      break;
    default:
      return;
  }

}
