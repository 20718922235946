import { useState } from 'react';
import { TwitterPicker } from 'react-color';
// import transparent from '../../assets/icons/transparent-bgcolor.svg' // This one doesn't work for some reason
import transparent from '../../assets/images/TransparentBg.png'
import styles from './ColorInput.module.scss';

function ColorInput(props) {
	const [showPicker, setShowPicker] = useState(false);

	const inputSquareStyle = () => {
		if (props.selectedColor?.hex) return {backgroundColor: props.selectedColor?.hex}
		else return { 
			backgroundColor: '', 
			background: `url(${transparent}) 1px 1px/21px 21px`, // url position/size
			borderRadius: '2px'
		}
	}

	return (
		<div className={styles.container}>
			<div
				className={styles.input}
				style={inputSquareStyle()}
				onClick={() => setShowPicker(!showPicker)}
			/>
			{showPicker && (
				<div className={styles.picker}>
					<TwitterPicker
						colors={props.colors}
						color={props.selectedColor && props.selectedColor}
						onChangeComplete={(color) => {
							props.onChange(color);
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default ColorInput;
