import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { changePassword, setAuthError, setAuthResponse } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './ChangePassword.module.scss';
import logo from '../../assets/icons/logo.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { MIN_PASSWORD_LENGTH } from '../../utils/constants/minPwLength';

/* User lands here via '/changePassword?token=<jwt>' from forgot-password email link 
	 and can reset their password here.  */
function ChangePassword() {
	const auth = useSelector((state) => state.auth);
	const t = useTranslation();
	const dispatch = useDispatch();
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [passwordRepeatError, setPasswordRepeatError] = useState('');

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	useEffect(() => {
		setPasswordError('');
		dispatch(setAuthError(''));
	}, [dispatch, password]);

	useEffect(() => {
		setPasswordRepeatError('');
		dispatch(setAuthError(''));
	}, [dispatch, passwordRepeat]);

	useEffect(() => {
		dispatch(setAuthResponse(''));
		dispatch(setAuthError(''));
	}, [dispatch]);

	function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		if (password.length === 0) {
			error = true;
			setPasswordError(t.login.fieldRequired);
		} else if (password.length < MIN_PASSWORD_LENGTH) {
			error = true;
			setPasswordError(t.register.passwordTooShort);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordError(t.register.passwordMismatch);
		}
		if (passwordRepeat.length === 0) {
			error = true;
			setPasswordRepeatError(t.login.fieldRequired);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordRepeatError(t.register.passwordMismatch);
		}
		if (!error) {
			dispatch(changePassword(query.get('token'), password));
			setPassword('');
			setPasswordRepeat('');
			setPasswordError('');
			setPasswordRepeatError('');
		}
	}

	function renderWarning() {
		if (auth.error !== '') {
			return auth.error;
		}
		if (auth.response !== '') {
			return auth.response;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<h1 className={styles.title}>
						{t.changePassword.password} <br />
						{t.changePassword.recovery}
					</h1>
					<h2 className={styles.subtitle}>
						{t.changePassword.chooseNew}
						<br /> {t.changePassword.safePassword}
					</h2>
				</div>
			</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img src={logo} className={styles.logo} alt="logo" />
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={passwordError}
						placeholder={t.changePassword.newPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={passwordRepeat}
						onChange={(e) => setPasswordRepeat(e.target.value)}
						error={passwordRepeatError}
						placeholder={t.changePassword.repeatPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={classNames(styles.warning, { [styles.success]: auth.response !== '' })}>
					{renderWarning()}
				</div>
				<div className={styles.submitWrapper}>
					<Button label={t.changePassword.savePassword} type="submit" primary />
				</div>
				<Link to="/login" className={styles.forgotPasswordLink}>
					{t.changePassword.backToLogin}
				</Link>
			</form>
		</div>
	);
}

export default ChangePassword;
