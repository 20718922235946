import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMuteButton } from '../../actions/audioplayerMuteButtonActions';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Audio from 'material-ui-audio-player';
import makeStyles from '@material-ui/core/styles/makeStyles';
import audioPlayerSidebarStyling from '../../utils/audioPlayerSidebarStyling';
import audioPlayerPreviewStyling from '../../utils/audioPlayerPreviewStyling';
import styles from './AudioPlayer.module.scss';

const AudioPlayer = (props) => {
	const dispatch = useDispatch();
	const [showPlayButton, setShowPlayButton] = useState(props.isFirstScene);
	const [play, setPlay] = useState(props.isFirstScene ? false : true);
	const isMuted = useSelector((state) => state.muteButton.isMuted);

	const muiTheme = createTheme({});
	const useStyles = makeStyles((muiTheme) => {
		if (props.autoplay) {
			return audioPlayerSidebarStyling(showPlayButton);
		} else {
			return audioPlayerPreviewStyling();
		}
	});

	const displayNone = makeStyles((muiTheme) => {
		return {
			root: {
				display: 'none',
			},
		};
	});

	useEffect(() => {
		setShowPlayButton(props.isFirstScene);
	}, [props.isFirstScene]);

	useEffect(() => {
		setPlay(!props.isFirstScene);
	}, [props.isFirstScene]);

	function playAudioFile() {
		setShowPlayButton(false);
		setPlay(!play);
	}

	function autoplay() {
		if (isMuted === true) {
			return false;
		}
		if (props.sidebar) {
			return false;
		}

		if (play) {
			return play;
		}
	}

	return (
		<ThemeProvider theme={muiTheme}>
			{showPlayButton && (
				<figure className={styles.playButton}>
					<button onClick={() => playAudioFile()} name="play"></button>
				</figure>
			)}
			{!showPlayButton ? (
				<>
					<Audio
						src={props.audioUrl}
						autoplay={autoplay()}
						controlsList="nodownload"
						useStyles={useStyles}
						order="reverse"
						muted={isMuted}
						onPlayed={() => {
							dispatch(setMuteButton(false));
						}}
					/>
					<button className={styles.muteButton} onClick={() => dispatch(setMuteButton(!isMuted))}>
						Mute Me
					</button>
				</>
			) : (
				<Audio useStyles={displayNone} />
			)}
		</ThemeProvider>
	);
};

export default AudioPlayer;
