import TextTemplatePreview from '../assets/images/TextTemplatePreview.svg';
import TextBildTemplatePreview from '../assets/images/TextBildTemplatePreview.svg';
import BildauswahlTemplatePreview from '../assets/images/BildauswahlTemplatePreview.png';
import SaulenTemplatePreview from '../assets/images/SaulenTemplatePreview.svg';
import KontaktFormularPreview from '../assets/images/KontaktFormularPreview.svg';
import VideoTemplatePreview from '../assets/images/VideoSceneThumbDefualt.svg';
import JsonTemplatePreview from '../assets/images/DatencheckTemplatePreview.svg';
import HtmlTemplatePreview from '../assets/images/HtmlTemplatePreview.jpg';
import FreetextTemplatePreview from '../assets/images/FreetextTemplatePreview.jpg';

export const TEMPLATE_DATA = [
	{
		img: TextTemplatePreview,
		transKey: 'question',
		templateName: 'text',
	},
	{
		img: TextBildTemplatePreview,
		transKey: 'questionWithPicture',
		templateName: 'text_image',
	},
	{
		img: BildauswahlTemplatePreview,
		transKey: 'imageSelection',
		templateName: 'images',
	},
	{
		img: SaulenTemplatePreview,
		transKey: 'columns',
		templateName: 'columns',
	},
	{
		img: KontaktFormularPreview,
		transKey: 'contactForm',
		templateName: 'contact',
	},
];

const additional_Professional_templates = [
	{
		img: KontaktFormularPreview,
		transKey: 'contactFormPro',
		templateName: 'contactPro',
	},
	{
		img: VideoTemplatePreview,
		transKey: 'video',
		templateName: 'video',
	},
	{
		img: JsonTemplatePreview,
		transKey: 'json',
		templateName: 'json',
	},
	{
		img: FreetextTemplatePreview,
		transKey: 'freeText',
		templateName: 'freeText',
	},
];
export const TEMPLATE_DATA_PROFESSIONAL = TEMPLATE_DATA
	.concat(additional_Professional_templates);

const additional_Admin_templates = [
		{
			img: HtmlTemplatePreview,
			transKey: 'html',
			templateName: 'html',
		},
	]
export const TEMPLATE_DATA_ADMIN = TEMPLATE_DATA_PROFESSIONAL
	.concat(additional_Admin_templates);