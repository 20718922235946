import SidebarBox from '../SidebarBox/SidebarBox';
import ColorInput from '../../components/ColorInput/ColorInput';
// import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-proj-card.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import { useTranslation } from '../../utils/useTranslation';
import { colors, buttonColors, buttonBackgroundColors } from '../../utils/colors';

import styles from './ColorBox.module.scss';

function ColorBox(props) {
	const t = useTranslation();
	return (
		<SidebarBox>
			<h2 className={styles.title}>{t.generalTab.colors}</h2>
			<div className={styles.inputGroup}>
				<ColorInput
					selectedColor={props.selectedProject?.general && props.selectedProject.general.backgroundColor}
					colors={colors}
					onChange={(color) =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, backgroundColor: color },
							})
						)
					}
				/>
				<label className={styles.label}>{t.generalTab.background}</label>
				<DeleteIcon 
					className={styles.deleteIcon} 
					onClick={() =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, backgroundColor: '' },
							})
						)} 						
					title={t.generalTab.removeBackground} 
				/>
			</div>
			<div className={styles.inputGroup}>
				<ColorInput
					selectedColor={props.selectedProject && props.selectedProject.general && props.selectedProject.general.color}
					colors={colors}
					onChange={(color) =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, color: color },
							})
						)
					}
				/>
				<label className={styles.label}>{t.generalTab.textColor}</label>
			</div>
			<div className={styles.inputGroup}>
				<ColorInput
					selectedColor={
						props.selectedProject &&
						props.selectedProject.general &&
						props.selectedProject.general.buttonBackgroundColor
					}
					colors={buttonBackgroundColors}
					onChange={(color) =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, buttonBackgroundColor: color },
							})
						)
					}
				/>
				<label className={styles.label}>{t.generalTab.buttonColor}</label>
			</div>
			<div className={styles.inputGroup}>
				<ColorInput
					selectedColor={
						props.selectedProject && props.selectedProject.general && props.selectedProject.general.buttonColor
					}
					colors={buttonColors}
					onChange={(color) =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, buttonColor: color },
							})
						)
					}
				/>
				<label className={styles.label}>{t.generalTab.buttonTextColor}</label>
			</div>

			<div className={styles.inputGroup}>
				<ColorInput
					selectedColor={props?.selectedProject?.general?.buttonColor2}
					colors={buttonColors}
					onChange={(color) =>
						props.dispatch(
							props.updateProject(props.token, {
								...props.selectedProject,
								general: { ...props.selectedProject.general, buttonColor2: color },
							})
						)
					}
				/>
				<label className={styles.label}>{t.generalTab.buttonTextColorType2}</label>
			</div>
		</SidebarBox>
	);
}

export default ColorBox;
