export const GET_ANALYTICS = 'GET_ANALYTICS';
export const ANALYITIC_ERROR = 'ANALYITIC_ERROR';

const apiUrl = process.env.REACT_APP_BE_URL;

export function getAnalyticsAction(data) {
	return {
		type: GET_ANALYTICS,
		payload: data,
	};
}

export const setAnalyticsError = (data) => {
	return {
		type: ANALYITIC_ERROR,
		payload: data,
	};
};

export const fetchAnalytics = async (userID) => {
	try {
		const analytics = await fetch(apiUrl + `/analytics/${userID}`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
		});
		if (analytics) {
			let resJSON = await analytics.json();
			return resJSON;
		}
	} catch (error) {
		setAnalyticsError(error);
	}
};

export const getAnalytics = (userID) => {
	return (dispatch) => {
		fetchAnalytics(userID).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setAnalyticsError(resJSON));
				} else {
					dispatch(getAnalyticsAction(resJSON));
				}
			} else {
				dispatch(setAnalyticsError(resJSON));
			}
		});
	};
};
