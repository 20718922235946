import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.scss';

function VideoPlayer(props) {
	const [showPlayButton, setShowPlayButton] = useState(props.isFirstScene);
	const [play, setPlay] = useState(false);

	useEffect(() => {
		setShowPlayButton(props.isFirstScene);
	}, [props.isFirstScene]);

	useEffect(() => {
		setPlay(!props.isFirstScene);
	}, [props.isFirstScene]);

	function playVideoFile() {
		setShowPlayButton(false);
		setPlay(!play);
	}

	return (
		<React.Fragment>
			{showPlayButton && (
				<figure className={styles.playButton}>
					<button onClick={() => playVideoFile()} name="play"></button>
				</figure>
			)}
			{!showPlayButton ? (
				<ReactPlayer
					width="100%"
					height="100%"
					url={props.videoUrl}
					className={showPlayButton ? styles.displayNone : styles.videoPlayer}
					// onStart={props.playVideo}
					onStart={() => setShowPlayButton(false)}
					playing={props.sidebar ? false : play}
					controls={!showPlayButton || props.sidebar}
					onProgress={props.autoplay ? (progress) => props.getPlaySeconds(progress) : () => {}}
					config={{ file: { attributes: { controlsList: 'nodownload' } } }}
					// TODO: picture in picture disable not work
					pip={false}
				/>
			) : (
				<img src={props.backgroundImage} width="100%" height="100%" alt="background" />
			)}
		</React.Fragment>
	);
}

export default VideoPlayer;
