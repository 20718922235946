import React, { useState } from 'react';
import SidebarBox from '../SidebarBox/SidebarBox';
import AudioUpload from '../AudioUpload/AudioUpload';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { useTranslation } from '../../utils/useTranslation';
import styles from './AudioBox.module.scss';
import ProgressBar from '../ProgressBar/ProgressBar';
import { showModal, hideModal } from '../../actions/modalActions';
import { ReactComponent as DoneIco } from '../../assets/icons/done.svg';
import { ReactComponent as DeleteIco } from '../../assets/icons/delete.svg';
import { deleteCloudinaryAudio } from '../../actions/scenesActions';
import {useSelector} from "react-redux";

function AudioBox(props) {
	const t = useTranslation();
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [audioName, setAudioName] = useState('');
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	function getProgress(progress) {
		if (progress > 0 && progress < 100) {
			setIsUploading(true);
		} else {
			setIsUploading(false);
		}
		if (progress === 100) {
			setIsUploading(false);
		}
		setProgress(progress);
	}

	function getAudioName(audioName) {
		setAudioName(audioName);
	}

	function handleAudioFileDelete() {
		props.dispatch(
			showModal('POPUP_MODAL', {
				title: t.audioVoiceover.audioFileDelete,
				buttonPrimary: {
					label: t.popup.yes,
					action: () => {
						const token = props.token;
						const scene = props.selectedScene;
						const sceneID = scene._id;
						scene.audioFileUrl = '';
						scene.audioFileName = '';
						props.dispatch(deleteCloudinaryAudio(token, scene.audioFilePublicId));
						props.dispatch(props.updateScene(token, sceneID, scene, projectHasDraftScenes));
						props.dispatch(hideModal());
					},
				},
				buttonSecondary: {
					label: t.popup.no,
					action: () => {
						props.dispatch(hideModal());
					},
				},
			})
		);
	}

	return (
		<SidebarBox>
			<div className={styles.inputGroup}>
				{props.selectedScene.audioFileUrl ? (
					<div className={styles.audioUploadedBox}>
						<span className={styles.audioUploadedLabel}>{audioName || props.selectedScene.audioFileName}</span>
						<DoneIco className={styles.doneIcon} />
						<DeleteIco className={styles.deleteIcon} onClick={handleAudioFileDelete} />
					</div>
				) : (
					<div>
						<label className={styles.label}>{t.audioVoiceover.addAudio}</label>
						{isUploading === false && (
							<div className={styles.imageRow}>
								<AudioUpload
									dispatch={props.dispatch}
									updateScene={props.updateScene}
									token={props.token}
									selectedScene={props.selectedScene}
									updateSceneProp={props.updateSceneProp}
									getProgress={getProgress}
									isUploading={isUploading}
									getAudioName={getAudioName}
								/>
							</div>
						)}
						{progress > 0 && progress < 100 && (
							<div className={styles.uploadingContainer}>
								<h6 className={styles.uploadFileLabel}>
									{t.audioVoiceover.uploadingFile} {parseInt(progress)}%
								</h6>
								<ProgressBar percentage={progress} />
							</div>
						)}
					</div>
				)}
			</div>

			{props.selectedScene.audioFileUrl && (
				<div className={styles.inputGroup}>
					<AudioPlayer audioUrl={props.selectedScene.audioFileUrl} autoplay={props.autoplay} sidebar={true} />
				</div>
			)}
		</SidebarBox>
	);
}

export default AudioBox;
