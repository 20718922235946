// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	return {
		root: {
			border: 'none',
			boxShadow: 'none',
			background: 'inherit',
			width: '250px',
			height: '24px',
			marginBottom: '10px',
			marginTop: '-30px',
		},
		playIcon: {
			width: '23px',
			height: '20px',
			color: '#A6A7AD',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
		},
		replayIcon: {
			width: '23px',
			height: '20px',
			color: '#A6A7AD',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
		},
		pauseIcon: {
			width: '23px',
			height: '20px',
			color: '#A6A7AD',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
		},
		volumeIcon: {
			width: '23px',
			height: '19px',
			color: '#A6A7AD',
			'&:hover': {
				color: '#716D6E',
			},
			opacity: '1',
		},
		volumeSlider: { display: 'none' },
		volumeSliderContainer: { display: 'none' },
		progressTime: { display: 'none' },
		mainSlider: {
			width: '100%',
			height: '4px',
			color: ' #A6A7AD',
			borderRadius: '35px',
			opacity: '0.5',
			marginTop: '5px',
			marginLeft: '-5%',
		},
	};
};
