import { ELEMENTS } from '../utils/constants/elements';

/* Creates string prepopulated with the classes corresponding to the selected template
	 for the Custom CSS box.
	 Called by CustomCSSBox.js from either SceneTab.js or GeneralTab.js.
	 'scope' is either 'project' or 'scene' depending on above.*/
export default function customClassesGenerator(selectedSceneOrProject, scope) {
	let elementsArray = [];
	if (scope === 'project') {
		// elementsArray = Object.values(ELEMENTS);  // obsolete: use all classes
		return ''
	} else {
		switch (selectedSceneOrProject.template) {
			case 'text':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.SCENE_DESCRIPTION,
					ELEMENTS.BUTTON
				];
				break;
			case 'text_image':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.SCENE_DESCRIPTION,
					ELEMENTS.BUTTON
				];
				break;
			case 'columns':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.SCENE_IMAGE_TITLE,
					ELEMENTS.SCENE_RICH_TEXT,
					ELEMENTS.SCENE_ANSWER_LABEL,
					ELEMENTS.SCENE_ANSWER_COLUMN
				];
				break;
			case 'images':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.SCENE_DESCRIPTION, 
					ELEMENTS.SCENE_ANSWER_LABEL
				];
				break;
			case 'contact':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.INPUT,
					ELEMENTS.SCENE_TOS, 
					ELEMENTS.BUTTON
				];
				break;
			case 'contactPro':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.INPUT,
					ELEMENTS.SCENE_TOS, 
					ELEMENTS.BUTTON
				];
				break;
			case 'video':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.BUTTON
				];
				break;
			case 'json':
				elementsArray = [
					ELEMENTS.SCENE_TITLE,
					ELEMENTS.SCENE_DESCRIPTION,
					ELEMENTS.SCENE_JSON_SOURCE,
					ELEMENTS.BUTTON,
					ELEMENTS.INPUT,
				];
				break;
			default:
				elementsArray = [ELEMENTS.SCENE_TITLE, ELEMENTS.BUTTON];
				break;
	}}

	let generatedCode = '';
	const prefix = `#${scope}-${selectedSceneOrProject._id}`;
	// eslint-disable-next-line array-callback-return
	elementsArray.map((element) => {
		generatedCode += `${prefix} .${element} {\n} \n\n`;
	});

	return generatedCode;
}
