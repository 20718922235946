import { GET_USERS, UPDATE_USERS, DELETE_USERS, USERS_ERROR, USERS_RESPONSE } from '../actions/usersActions';

const initialState = { data: [], response: '', error: '' };

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USERS:
			return { ...state, data: action.payload };
		case UPDATE_USERS:
			return {
				...state,
				data: state.data.map((item) => {
					if (item._id === action.payload._id) {
						return action.payload;
					} else {
						return item;
					}
				}),
			};
		case DELETE_USERS:
			return { ...state, data: state.data.filter((item) => item.email !== action.payload) };
		case USERS_ERROR:
			return { ...state, response: '', error: action.payload };
		case USERS_RESPONSE: {
			return { ...state, response: action.payload, error: '' };
		}
		default:
			return state;
	}
}
