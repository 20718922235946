export const colors = ['#000000', '#4D4F5C', '#9799A2', '#F0F0F0', '#FFFFFF', '#BA8888', '#889DBA', '#A9BA88'];

export const buttonColors = ['#000000', '#4D4F5C', '#9799A2', '#F0F0F0', '#FFFFFF', '#BA8888', '#889DBA', '#A9BA88'];

export const buttonBackgroundColors = [
	'#000000',
	'#4D4F5C',
	'#9799A2',
	'#F0F0F0',
	'#FFFFFF',
	'#BA8888',
	'#889DBA',
	'#A9BA88',
];

export const defaultColors = [
	'#B80000',
	'#DB3E00',
	'#FCCB00',
	'#008B02',
	'#006B76',
	'#1273DE',
	'#004DCF',
	'#5300EB',
	'#EB9694',
	'#FAD0C3',
	'#FEF3BD',
	'#C1E1C5',
	'#BEDADC',
	'#C4DEF6',
	'#BED3F3',
	'#D4C4FB',
];
