import getOptimizedImage from "./getOptimizedImage";

export const templateBackground = (backgroundImage, backgroundColor) => {
	if (backgroundImage) return  {
		backgroundImage: 'url(' + getOptimizedImage(backgroundImage) + ')'
	};
	else if (backgroundColor) return  {
		backgroundImage: '',
		background: backgroundColor.hex,
	}
}