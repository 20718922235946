
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import SidebarBox from '../SidebarBox/SidebarBox';
import ImageUpload from '../ImageUpload/ImageUpload';
import RichText from '../RichText/RichText';
import Input from '../../components/Input/Input';
import Toggle from '../Toggle/Toggle';
import { ROLES } from '../../utils/constants/roles';
import { deleteImage } from "../../utils/deleteImage";
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import styles from './ImageTextBox.module.scss';
import {useSelector} from "react-redux";

/* Called by SceneTab.js for 'text_image', 'images', 'text' and 'json' templates.
	 It renders the 'add picture', upload + conditions and alt meta tag for 'text_image' templates and
	 scene description and price for all templates listed above.
*/
function ImageTextBox({ dispatch, selectedScene, token, updateScene, updateSceneProp }) {
	const t = useTranslation();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	return (
		<SidebarBox>
			{selectedScene?.template === 'text_image' && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.addImage}</label>
					<div className={styles.imageRow}>
						<ImageUpload
							onUpload={(imageUrl, imagePublicId) => {
								dispatch(updateScene(
									token,
									selectedScene._id,
									{ imageUrl: imageUrl, imagePublicId: imagePublicId },
									projectHasDraftScenes
								));
							}}
							imageUrl={selectedScene.imageUrl}
							index={0}
							token={token}
						/>
						<span className={styles.condition}>{t.imageUpload.minMaxImageSize}</span>
						{selectedScene.imageUrl &&
							<span className={styles.delete}>
								<DeleteIcon
									className={styles.deleteIcon}
									onClick={() => deleteImage(token, 'text_image', selectedScene, updateScene, dispatch)}
									title={t.generalTab.deleteImage}
								/>
							</span>
						}
					</div>

					<div className={styles.inputGroup}>
						<label className={styles.label}>{t.sceneTab.imageDescription}</label>
						<Input
						type="text"
						name="imageDescription"
						value={selectedScene.imageDescription}
						onChange={(e) => dispatch(updateSceneProp(selectedScene._id, e.target.name, e.target.value))}
						onBlur={() =>
							dispatch(
								updateScene(
									token,
									selectedScene._id,
									{ imageDescription: selectedScene.imageDescription },
									projectHasDraftScenes
								)
							)
						}
						secondary
						/>
					</div>

					{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
						<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
						<Toggle
							checked={selectedScene.retrieveOrigSize}
							onChange={() => {
								dispatch(
									updateScene(
										token,
										selectedScene._id,
										{ retrieveOrigSize: !selectedScene.retrieveOrigSize },
										projectHasDraftScenes
									)
								);
							}}
						/>
						<label className={styles.label}>{t.generalTab.yes}</label>
					</div>}

				</div>
			)}
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.sceneTab.description}</label>
				<RichText
					selectedScene={selectedScene}
					onBlur={() =>
						dispatch(
							updateScene(token, selectedScene._id, { richText: selectedScene.richText }, projectHasDraftScenes)
						)
					}
					onChange={(e) => {
						dispatch(updateSceneProp(selectedScene._id, 'richText', e));
					}}
					defaultValue={selectedScene.richText}
				/>
			</div>
			{selectedScene?.template === 'text_image' && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.price}</label>
					<Input
						type="text"
						name="price"
						value={selectedScene.price}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								dispatch(updateSceneProp(selectedScene._id, 'price', e.target.value));
							}
						}}
						onBlur={() => {
							dispatch(
								updateScene(token, selectedScene._id, { price: selectedScene.price }, projectHasDraftScenes)
							);
						}}
						secondary
						error={selectedScene.price && selectedScene.price.length >= 10 && t.sceneTab.errors.answer}
						errorstyle={{ fontSize: '12px' }}
					/>
				</div>
			)}
		</SidebarBox>
	);
}

export default ImageTextBox;
