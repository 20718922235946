/* TODO: remove the unused action creators and corresponding reducers.
	 Then consider whether it makes sense to keep fetchGetInquiries and 
	 fetchDownloadInquiries here, or if it would be better to move them
	 either into Inquiries.js, into the Inquiries folder or into a new
	 folder called 'API calls', for example.
*/
export const GET_INQUIRIES = 'GET_INQUIRIES';
export const CAN_ACCESS = 'CAN_ACCESS';
export const INQUIRIES_ERROR = 'INQUIRIES_ERROR';

const apiUrl = process.env.REACT_APP_BE_URL;
const token = localStorage?.token;

export function getInquiriesAction(data) {
	return {
		type: GET_INQUIRIES,
		payload: data,
	};
}

export const setInquiriesError = (data) => {
	return {
		type: INQUIRIES_ERROR,
		payload: data,
	};
};

export const fetchGetInquiries = async (beraterId, startDate, endDate, offset, maxPerPage) => {
	try {
		const inquiries = await fetch(apiUrl + `/getInquiries/${beraterId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate,
				endDate,
				offset,
				maxPerPage
			}),
		});
		if (inquiries) {
			let resJSON = await inquiries.json();
			return resJSON;
		}
	} catch (error) {
		setInquiriesError(error);
	}
};

/* Is this function used for anything anymore?
	 Inquiries.js now calls fetchGetInquiries directly without sending
	 the inquiries to Redux.
*/
export const getInquiries = (beraterId, startDate, endDate, offset, maxPerPage) => {
	return (dispatch) => {
		fetchGetInquiries(beraterId, startDate, endDate, offset, maxPerPage).then((resJSON) => {
			if (resJSON) {
				if (resJSON.sessionData === null) {
					dispatch({
						type: CAN_ACCESS,
						payload: resJSON.inquiriesData,
					});
				}
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setInquiriesError(resJSON));
				} else {
					dispatch(getInquiriesAction(resJSON));
				}
			} else {
				dispatch(setInquiriesError(resJSON));
			}
		});
	};
};

export const fetchDownloadInquiries = async (beraterId, startDate, endDate) => {
	try {
		const inquiries = await fetch(apiUrl + `/getInquiries/download/id/${beraterId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate: startDate,
				endDate: endDate,
			}),
		});

		return inquiries && (await inquiries.json());
	} catch (error) {
		console.log('Something went wrong getting inquiries for download. Error: ', error);
	}
};