import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from '../../utils/useTranslation';
import styles from './JsonTemplate.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { showModal, hideModal } from '../../actions/modalActions';
import Loader from 'react-loader-spinner-svg';
import 'react-loader-spinner-svg/dist/loader/css/react-spinner-loader.css';
import { setJsonTemplateKeys } from '../../actions/jsonTemplateActions';
import Parser from 'html-react-parser';
import { templateBackground } from '../../utils/templateBackground';

function JsonTemplate(props) {
	const t = useTranslation();
	const dispatch = useDispatch();

	const [hoverBack, setHoverBack] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [searchValueError, setSearchValueError] = useState('');
	const [fileData, setFileData] = useState([]);
	const [foundObject, setFoundObject] = useState(null);
	const [loading, setLoading] = useState(false);
	const [inputSugestions, setInputSugestions] = useState(null);

	useEffect(() => {
		props.scene.jsonFileUrl && readJsonFile(props.scene.jsonFileUrl);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.scene.jsonFileUrl]);

	useEffect(() => {
		props.scene.jsonSearchKey && setSearchKey(props.scene.jsonSearchKey);
	}, [props.scene.jsonSearchKey]);

	useEffect(() => {
		if (fileData) {
			//Getting available keys from JSON file
			let keys = [];
			if (fileData.length > 0) {
				for (let d of fileData) {
					let objectKeys = Object.keys(d);

					for (let key of objectKeys) {
						keys.push(key);
					}
				}

				let removedDuplicateKeys = keys.filter(function(value, index, array) {
					return array.indexOf(value) === index;
				});
				if (removedDuplicateKeys) {
					dispatch(setJsonTemplateKeys(removedDuplicateKeys));
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileData]);

	function showPopup(title, answer, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function readJsonFile(url) {
		readTextFile(`${url}`, function (text) {
			var data = JSON.parse(text); //parse JSON
			setFileData(data);
		});
	}

	function readTextFile(file, callback) {
		var rawFile = new XMLHttpRequest();
		rawFile.overrideMimeType('application/json');
		rawFile.open('GET', file, true);
		rawFile.onreadystatechange = function () {
			if (rawFile.readyState === 4 && rawFile.status === 200) {
				callback(rawFile.responseText);
			}
		};
		rawFile.send(null);
	}

	function handleSubmit() {
		if (props.scene.jsonFileUrl === undefined) {
			return showPopup(t.jsonTemplate.fileNotFound, '', t.jsonTemplate.pleaseUploadFile);
		}
		const TIMEOUT = 1000;
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, TIMEOUT);

		if (searchValue.length < 1) {
			return setSearchValueError(t.jsonTemplate.enterValidInput);
		}

		let noResultsFound = true;
		if (fileData) {
			try {
				for (let data of fileData) {
					if (data[searchKey].toString().toLocaleLowerCase() === searchValue.toString().toLocaleLowerCase()) {
						setFoundObject(data);
						noResultsFound = false;
					}
				}
			} catch (error) {
				return showPopup(t.jsonTemplate.wrongJsonKey, '', t.jsonTemplate.checkJsonKeyValue);
			}
		}
		if (noResultsFound) {
			return setSearchValueError(t.jsonTemplate.noResultForSelection);
		}
		setSearchValueError('');
	}

	function handleChange(e) {
		setSearchValueError('');
		let allValuesForSearchKey = [];
		for (let data of fileData) {
			allValuesForSearchKey.push(data[searchKey]);
		}
		const newSearchValue = e.target.value;
		setSearchValue(newSearchValue);
		if (newSearchValue.length > 1) {
			let suggestions = [];
			try {
				suggestions =
					allValuesForSearchKey &&
					allValuesForSearchKey.filter(
						(suggestion) => suggestion.toString().toLowerCase().indexOf(newSearchValue.toString().toLowerCase()) > -1
					);
				setInputSugestions(
					suggestions.map((suggestion, index) => {
						return (
							<li key={index} className={'item'} onClick={() => handleClick(suggestion)}>
								{suggestion}
							</li>
						);
					})
				);
			} catch (error) {
				return showPopup('Something is wrong with JSON search key', '', 'JSON search key must be same as one in file');
			}
		}
	}

	function handleClick(suggestion) {
		setSearchValue(suggestion);
		setInputSugestions(null);
	}

	function formatDescription(text) {
		let splitedText = text.split(' ');
		let counter = 0;
		for (let word of splitedText) {
			let openingBrackets = '{';
			let closingBrackets = '}';

			if (word.includes(openingBrackets) && word.includes(closingBrackets)) {
				const entries = Object.entries(foundObject);
				let wordWithRemovedBrackets = word.replace(/[^a-z]/gi, '');
				// eslint-disable-next-line no-loop-func
				entries.forEach(entry => {
					if (entry[0].toLocaleLowerCase() === wordWithRemovedBrackets.toLocaleLowerCase()) {
						let entryValue = entry[1];
						let entrySpan = `<span style="font-weight: bold">${entryValue}</span>`;
						splitedText[counter] = entrySpan;
					}
				});
			}
			counter++;
		}
		let splitedTextString = splitedText.toString();
		let formatedString = splitedTextString.replaceAll(',', ' ');
		return (
			<div className={styles.description} style={{ color: props?.project?.general?.color?.hex }}>
				{Parser(formatedString)}
			</div>
		);
	}
	return (
		<React.Fragment>
			{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
				<ChevronLeft
					className={styles.backButton}
					onClick={() => props.goBack()}
					style={{
						fill:
							props.project &&
							props.project.general &&
							props.project.general.buttonBackgroundColor &&
							props.project.general.buttonBackgroundColor.hex &&
							(hoverBack
								? lightenDarkenColor(props.project.general.buttonBackgroundColor.hex, -40)
								: props.project.general.buttonBackgroundColor.hex),
					}}
					onMouseEnter={() => setHoverBack(true)}
					onMouseLeave={() => setHoverBack(false)}
				/>
			)}

			<div
				className={classNames(styles.template)}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				{foundObject !== null ? (
					loading ? (
						<Loader type="TailSpin" color="#00BFFF" height={40} width={40} visible={loading} />
					) : (
						<div style={styles.jsonResolution}>
							<h1 className={styles.result} style={{ color: props?.project?.general?.color?.hex }}>
								{t.jsonTemplate.result}
							</h1>
							{props.scene.jsonVariablesDescripton && formatDescription(props.scene.jsonVariablesDescripton)}

							<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
								<div className={styles.secondSceneButtonWrapper}>
									<Button
										label={t.jsonTemplate.continue}
										secondary
										onClick={() => {}}
										// onClick={() => {
										// 	props.goTo(props.scene.answers[0].sceneId, searchValue);
										// 	setFoundObject(null);
										// 	setSearchValue('');
										// }}
										style={{
											minWidth: props.scene.answers.length === 4 && '80px',
											color: props?.project?.general?.buttonColor?.hex,
											backgroundColor: props?.project?.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							</span>
							<div className={styles.source} style={{ color: props?.project?.general?.color?.hex }}>
								{props.scene.jsonFileSource}
							</div>
						</div>
					)
				) : (
					<div>
						<span className={classNames(styles.templateRow)}>
							{!props.hideSceneName && (
								<div className={styles.titleWrapper}>
									<span className={styles.title}>{props.scene && props.scene.title}</span>
								</div>
							)}
							<h1 className={classNames(styles.question)} style={{ color: props?.project?.general?.color?.hex }}>
								{props.scene && props.scene.question}
							</h1>

							<div>
								{props.scene && props.scene.richText && (
									<p
										className={styles.richText}
										dangerouslySetInnerHTML={{ __html: props.scene.richText }}
										style={{ color: props.project?.general?.color?.hex }}
									></p>
								)}
							</div>
							<span className={styles.inputRow} style={{ display: 'block' }}>
								<Input
									type='text'
									value={searchValue}
									name='searchKey'
									onChange={() => {}}
									squared
									template
									placeholder={searchKey && t.jsonTemplate.here + searchKey.toUpperCase() + t.jsonTemplate.enter}
									error={searchValueError}
									errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
								/>
							</span>
							{inputSugestions ? (
								<div className={styles.suggestions}>
									<ul>{inputSugestions}</ul>
								</div>
							) : (
								<div></div>
							)}

							<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
								<div className={styles.buttonWrapper}>
									<Button
										label={t.jsonTemplate.check}
										secondary
										onClick={() => {}}
										style={{
											color: props?.project?.general?.buttonColor?.hex,
											backgroundColor: props?.project.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							</span>
						</span>
						{props.children}   {/* obsolete with React-Flow */}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default JsonTemplate;
