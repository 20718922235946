import { useState } from 'react';
import classNames from 'classnames';

import styles from './SelectInput.module.scss';

function SelectInput(props) {
	const [open, setOpen] = useState(false);

	const options = props.options.map((option, index) => {
		if (option.label) {
			return (
				<span
					key={index}
					className={classNames(styles.option, {
						[styles.ternaryOption]: props.ternary,
					})}
					onClick={() => {
						option.onClick();
					}}
				>
					{option.label}
				</span>
			);
		}
		else return null
	});

	function renderTrigger() {
		if (props.navbar) {
			return props.selected;
		} else {
			return props.options.map((option) => {
				if (option.value === '') {
					return '';
				} else {
					if (props.selected === option.value) {
						return option.label;
					}
				}
				return '';
			});
		}
	}

	return (
		<div className={styles.wrapper} tabIndex="0" onClick={() => setOpen(!open)} onBlur={() => setOpen(false)}>
			<div
				className={classNames(styles.select, {
					[styles.open]: open === true,
					[styles.ternarySelect]: props.ternary,
				})}
			>
				<div
					className={classNames(styles.trigger, {
						[styles.ternaryTrigger]: props.ternary,
						[styles.disabledTrigger]: props.disabled,
					})}
				>
					<span>{renderTrigger()}</span>
					<div className={styles.arrow}></div>
				</div>
				<div
					className={classNames(styles.options, {
						[styles.ternaryOptions]: props.ternary,
						[styles.noScroll]: props.noScroll,
					})}
				>
					{options}
				</div>
			</div>
		</div>
	);
}

export default SelectInput;
