export function getValidUrl(url = '') {
	let newUrl = window.decodeURIComponent(url);
	newUrl = newUrl.trim().replace(/\s/g, '');
	if (/^(f|ht)tps?:\/\//i.test(newUrl) || /^(f|ht)tp?:\/\//i.test(newUrl)) {
		return newUrl;
	} else if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
		return `https://${newUrl}`;
	} else if (!/^(f|ht)tp?:\/\//i.test(newUrl)) {
		return `http://${newUrl}`;
	}
	return newUrl;
}
