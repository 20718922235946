
import { Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Answer from '../../components/Answer/Answer';
import { updateSceneAnswer, updateAnswer, removeAnswer } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import RichText from '../RichText/RichText';
import styles from './FreeTextBox.module.scss';
import SelectInput from '../SelectInput/SelectInput';
import {useSelector} from "react-redux";

/*	Renders description, text/textarea, placeholder, answer required?, default answer
		and button-label (answer) for configuration
		Called by SceneTab.js
		Updates the freeText object on the scene: {
			description: <rich text>,
			inputType: 'text'/'textarea',
			placeholder: string,
			required: boolean,
			defaultAnswer: string
		}
*/
function FreeTextBox(props) {
	const t = useTranslation();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	const scene = props.selectedScene;

	return (
		<SidebarBox>
			<div className={styles.inputGroupDescription}>
				<label className={styles.label}>{t.sceneTab.description}</label>
				<RichText
					selectedScene={scene}
					onChange={(e) => {
						props.dispatch(
							props.updateSceneProp(scene._id, 'freeText', {
								...scene.freeText, description: e,
							})
						);
					}}
					onBlur={() => {
						props.dispatch(
							props.updateScene(props.token, scene._id, { freeText: scene.freeText}, projectHasDraftScenes)
						);
					}}
					defaultValue={scene?.freeText.description || t.sceneTab.description}
				/>
			</div>

			<div className={styles.box}>
				<div className={classNames(styles.inputGroup, styles.zIndex)}>
					<SelectInput
						options={[
							{
								value: 'text',
								label: t.sceneTab.shortText,
								onClick: () => {
									props.dispatch(
										props.updateScene(
											props.token,
											scene._id,
											{ freeText: {...scene.freeText, inputType: 'text'} },
											projectHasDraftScenes)
									);
								}
							},
							{
								value: 'textarea',
								label: t.sceneTab.textarea,
								onClick: () => {
									props.dispatch(
										props.updateScene(
											props.token,
											scene._id,
											{ freeText: {...scene.freeText, inputType: 'textarea'} },
											projectHasDraftScenes
										)
									);
								}
							}
						]}
						selected={scene.freeText.inputType}
						ternary
					/>
				</div>

				<label className={styles.placeholderLabel}>
						{t.sceneTab.placeholder}
				</label>
				<input
					type="text"
					value={scene.freeText.placeholder}
					className={styles.otherInput}
					onChange={(e) => {
						props.dispatch(
							props.updateSceneProp(scene._id, 'freeText', {
								...scene.freeText, placeholder: e.target.value,
							})
						);
					}}
					onBlur={() => {
						props.dispatch(
							props.updateScene(props.token, scene._id, { freeText: scene.freeText }, projectHasDraftScenes)
						);
					}}
				/>

				<div className={styles.requiredField}>
					<Form.Group as={Row} className='justify-content-between align-items-center'>
						<Form.Label column sm="7">
							{t.sceneTab.requiredField}
						</Form.Label>
						<Col sm="5">
							<Form.Switch
								checked={scene.freeText.required}
								onChange={() => {
									props.dispatch(
										props.updateScene(
											props.token,
											scene._id,
											{	freeText: { 	...scene.freeText, required: !scene.freeText.required } },
											projectHasDraftScenes
										)
									);
								}}
								id='form-switch'
								size='lg'
							/>
						</Col>
					</Form.Group>
				</div>

				{!scene.freeText.required && <div>
					<label className={styles.defaultAnswerLabel}>
						{t.sceneTab.defaultAnswer}
					</label>
					<input
						type="text"
						value={scene.freeText.defaultAnswer}
						className={styles.otherInput}
						onChange={(e) => {
							props.dispatch(
								props.updateSceneProp(scene._id, 'freeText', {
									...scene.freeText, defaultAnswer: e.target.value,
								})
							);
						}}
						onBlur={() => {
							props.dispatch(
								props.updateScene(props.token, scene._id, { freeText: scene.freeText }, projectHasDraftScenes)
							);
						}}
					/>
				</div>}

				<div className={styles.inputGroup}>
					<Answer
						key={0}
						index={0}
						answer={scene.answers[0]}
						scenes={props.scenes}
						selectedScene={scene}
						token={props.token}
						dispatch={props.dispatch}
						updateSceneAnswer={updateSceneAnswer}
						updateAnswer={updateAnswer}
						removeAnswer={removeAnswer}
						contact
					/>
				</div>
			</div>
		</SidebarBox>
	);

}

export default FreeTextBox;
