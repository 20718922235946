import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-mini.svg';
import Button from '../Button/Button';
import SidebarBox from '../SidebarBox/SidebarBox';
import { fetchUsers } from '../../actions/usersActions';
import { showModal, hideModal } from '../../actions/modalActions';
import { fetchProjectOwnerChange, getProjects } from '../../actions/projectsActions';
import { useTranslation } from '../../utils/useTranslation';
import styles from './ChangeOwnerBox.module.scss';

/* called from GeneralTab.js
	 changes the owner of this Berater and all related scenes
	 and then sends the user back to ProjectList.js */
const ChangeOwnerBox = ({ project }) => {
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const [currentOwner, setCurrentOwner] = useState({ _id: '', email: '', username: '' });
	const [originalOwner, setOriginalOwner] = useState({ _id: '', email: '', username: '' });
	const [editOwner, setEditOwner] = useState(false);
	const [typedUser, setTypedUser] = useState({ _id: '', email: '', username: '' });
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [showUserList, setShowUserList] = useState(false);

	useEffect(()=>{
		let mounted = true; // cleanup function to avoid attempts to setState after component is unmounted.
		(async () => {
			const userArray = await fetchUsers(token);
			if (mounted) {
				setAllUsers(userArray.users);
				setFilteredUsers(userArray.users);
				const owner = userArray.users.find(user => user._id === project.userId);
				const ownerObj = { _id: owner._id, email: owner.email, username: owner.username };
				setOriginalOwner(ownerObj);
				setCurrentOwner(ownerObj);
			}
		})();
		return () => mounted = false;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const filterUser = (event) => {
    const typedLetters = event.target.value;
    setTypedUser({...typedUser, username: typedLetters});
    setShowUserList(true);
    const possibleUsers = allUsers.filter(user => user.username.toLowerCase().includes(typedLetters.toLowerCase()))
    setFilteredUsers(possibleUsers);
  }

	const selectUser = ({ _id, email, username }) => {    
    setTypedUser({ _id, email, username });
    setShowUserList(false);
  }

	const submitNewOwner = () => {
		setCurrentOwner(typedUser)
		setEditOwner(false);
		showPopup(t.generalTab.confirmNewOwner, t.generalTab.newOwnerWarning)
	}

	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: async () => {
						// use 'typedUser' since 'currentOwner' hasn't been updated yet...
						fetchProjectOwnerChange(token, project._id, typedUser._id);
						// update projects in redux
						dispatch(getProjects(token));
						dispatch(hideModal());
						history.push('/projectList');
					},
				},
				buttonSecondary: {
					label: t.popup.no,
					action: () => {
						setCurrentOwner(originalOwner);
						setTypedUser({ _id: '', email: '', username: '' })
						dispatch(hideModal());
					},
				},
			})
		);
	}

	return (
		<SidebarBox>
			<div className={styles.box}>
				<label className={styles.owner}>{t.generalTab.owner}:</label>
				<Row>
					<Col xs={10}>
						<label className={styles.name}>{currentOwner.username}<br/>({currentOwner.email})</label>
					</Col>
					<Col style={{padding: '0'}}>
						<PenIcon
							alt="PenIcon"
							className={styles.icon} 
							onClick={() => setEditOwner(true)} 
						/>
					</Col>
				</Row>
				{editOwner && <div className={styles.form}>
					<Form.Control 
						value={typedUser.username} 
						onChange={filterUser}
						type="text" 
						placeholder={typedUser.username || t.generalTab.BeginTypingNewOwnersUsername }
						style={{ fontSize: '12px', padding: '5px' }}
					/>
					{(filteredUsers.length > 0 && showUserList) && <div>
						<div className={styles.instructions}>{t.generalTab.TypeAndSelectNewOwnerFromList}:</div>
						<Dropdown.Menu show style={{ position: 'relative', padding: '0' }}>
							{filteredUsers.map(user => {
								return (
									<Dropdown.Item 
										key={user._id} 
										onClick={() => selectUser(user)}
										style={{ fontSize: '13px', padding: '4px' }}
									>
										{user.username}<br/>({user.email})
									</Dropdown.Item>
								)
							})}
						</Dropdown.Menu>
					</div>} 

					<Button onClick={() => submitNewOwner()} secondary small label={t.sceneTab.save} 
						style={{ marginTop: '5px', marginLeft: '130px', fontSize: '12px', padding: '2px'}}
					/>
				</div>}			
				
			</div>
		</SidebarBox>
	);

}

export default ChangeOwnerBox;
