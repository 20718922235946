import { SET_WIDGET_SCRIPT_LOADED } from '../actions/previewActions';

const initialState = {
	widgetScriptLoaded: false,
};

export default function widgetScriptReducer(state = initialState, action) {
	switch (action.type) {
		case SET_WIDGET_SCRIPT_LOADED:
			return { ...state, widgetScriptLoaded: action.payload };
		default:
			return state;
	}
}
