
import { Handle } from 'reactflow';
import classNames from 'classnames';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import Template from '../../components/Template/Template';
import styles from './Canvas.module.scss';
import { HANDLE_ID_LETTERS } from '../../utils/constants/flowNodeHandles';
import { baseBlueColor } from "../../utils/constants/colors";

// const scene =
	// {"template":"columns","answers":[{"answer":"Ja","imageTitle":"","richText":"","price":"","imageUrl":""},{"answer":"Nein","imageTitle":"","richText":"","price":"","imageUrl":""}],"hasBackButton":true,"imageUrl":"","imageDescription":"","imageSize":"100","richText":"","price":"","fields":[{"question":"Was ist deine Lieblingsfarbe?","type":"text","options":["Grün","Gelb","Rot"]}],"isFirstScene":false,"hasCodeSnippet":false,"hasCustomCss":false,"_id":"6364dba1dfcea9698057a8e7","title":"Szene 3","question":"Frage 3","projectId":"6364db97dfcea9698057a8d9","position":{"x":400,"y":300},"updated":"2022-11-08T10:55:05.591Z","created":"2022-11-04T09:30:09.998Z","userId":"5fa91e8744a346cea0c834d6","__v":0}

const handlePosOdd = ['50%', '38%', '62%', '26%', '74%', '14%', '86%'];
const handlePosEven = ['44%', '56%', '32%', '68%', '20%', '80%', '08%', '92%'];

// TODO: remove console.logs

/* Called by Flow.js.
   Renders the individual scene on the canvas. */
const RenderScene = ({ scene, selectedSceneIds, setSelectedSceneIds, copyScene, deleteScene, templateType, project }) => {  // old props: ({ data, isConnectable })

	const isSelectedScene = selectedSceneIds.includes(scene._id);

	// console.log('scene:', scene)
	const ansLength = scene.answers.length;
	let positions = [];
	if (ansLength % 2 === 0) positions = handlePosEven.slice(0, ansLength).sort();
	else positions = handlePosOdd.slice(0, ansLength).sort();
	// console.log('project:', project)

	return (
		<div
			onClick={(event) => {
				event.stopPropagation();
				console.log('current scene._id:', scene._id)
				// if SHIFT was not pressed, this scene is the new single selection
				if (!event.shiftKey) {
					setSelectedSceneIds([scene._id])
				} else if (!isSelectedScene && event.shiftKey) {
					// if SHIFT was pressed, add this scene to the current selection
					setSelectedSceneIds([...selectedSceneIds, scene._id])
				}
				console.log('isSelectedScene?:', isSelectedScene)
			}}
			className={classNames(styles.scene, {[styles.selected]: isSelectedScene})}
		>
			<Handle
				type="target"
				position="top"
				id="a"
				style={{ background: baseBlueColor, left: '50%', height: 15, width: 15, borderRadius: '50% 50% 0 0', top: -11, zIndex: -1 }}
			/>
			<div style={{backgroundColor: project.general.backgroundColor?.hex, width: 350, height: 200}}>
				<Template
					scene={scene}
					goTo={() => {}}
					templateType={templateType}
					project={project}
				/>
				<span
					onClick={copyScene}
					// onClick={(e) => {
					// 	e.stopPropagation()
					// 	copyScene()
					// 	console.log('isCopyActive set to true')
					// }}
					className={styles.copyButton}
				>
					<CopyIcon className={styles.copyIcon} />
				</span>
				<span
					onClick={deleteScene}
					className={styles.deleteButton}
				>
					✖
				</span>
				{/*Custom Color Picker Node: <strong>{data.color}</strong>*/}
				{/*<input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} />*/}
			</div>
			{scene.answers.map( (ans, idx) => {
				return 	<Handle
						key={idx}
						type="source"
						position="bottom"
						id={HANDLE_ID_LETTERS[idx]}
						style={{ background: baseBlueColor, left: positions[idx], height: 15, width: 15, borderRadius: '0 0 50% 50%', bottom: -11, zIndex: -1 }}
					/>
			})}
			{/* <Handle
				type="source"
				position="bottom"
				id="a"
				style={{ background: 'blue', left: '40%', height: 18, width: 18, bottom: -8 }}
			/>
			<Handle
				type="source"
				position="bottom"
				id="b"
				style={{ background: 'red', left: '60%', height: 18, width: 18, bottom: -8 }}
			/> */}
		</div>
	);
};
export default RenderScene
