import React from 'react';
import classNames from 'classnames';

import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Input from '../Input/Input';
import Answer from '../../components/Answer/Answer';
import { updateSceneAnswer, updateAnswer, removeAnswer } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';
import RichText from '../RichText/RichText';

import styles from './ContactBox.module.scss';
import {useSelector} from "react-redux";

function ContactBox(props) {
  const t = useTranslation();
  const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

  return (
    <SidebarBox>
      <div className={styles.box}>
        <label className={styles.label}>{t.sceneTab.contactSection.contactFields}</label>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.name && props.selectedScene.contact.name.value}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: {
                      ...props.selectedScene.contact,
                      name: {
                        ...(props.selectedScene.contact.name && props.selectedScene.contact.name),
                        value: props.selectedScene.contact.name && !props.selectedScene.contact.name.value,
                      },
                    },
                  },
                  projectHasDraftScenes
                )
              );
            }}
          />
          <input
            type="text"
            name="contact"
            value={
              props.selectedScene.contact.name ? props.selectedScene.contact.name.name : t.sceneTab.contactSection.name
            }
            className={styles.otherInput}
            onChange={(e) => {
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  name: {
                    ...(props.selectedScene.contact.name && props.selectedScene.contact.name),
                    name: e.target.value,
                  },
                })
              );
            }}
            onBlur={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: props.selectedScene.contact },
                  projectHasDraftScenes
                )
              );
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.surname && props.selectedScene.contact.surname.value}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: {
                      ...props.selectedScene.contact,
                      surname: {
                        ...(props.selectedScene.contact.surname && props.selectedScene.contact.surname),
                        value: props.selectedScene.contact.surname && !props.selectedScene.contact.surname.value,
                      },
                    },
                  },
                  projectHasDraftScenes
                )
              );
            }}
          />
          <input
            type="text"
            name="contact"
            value={
              props.selectedScene.contact.surname
                ? props.selectedScene.contact.surname.name
                : t.sceneTab.contactSection.surname
            }
            className={styles.otherInput}
            onChange={(e) => {
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  surname: {
                    ...(props.selectedScene.contact.surname && props.selectedScene.contact.surname),
                    name: e.target.value,
                  },
                })
              );
            }}
            onBlur={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: props.selectedScene.contact },
                  projectHasDraftScenes
                )
              );
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.email && props.selectedScene.contact.email.value}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: {
                      ...props.selectedScene.contact,
                      email: {
                        ...(props.selectedScene.contact.email && props.selectedScene.contact.email),
                        value: props.selectedScene.contact.email && !props.selectedScene.contact.email.value,
                      },
                    },
                  },
                  projectHasDraftScenes
                )
              );
            }}
            disabled
          />
          <input
            type="text"
            name="contact"
            value={
              props.selectedScene.contact.email
                ? props.selectedScene.contact.email.name
                : t.sceneTab.contactSection.email
            }
            className={styles.otherInput}
            onChange={(e) => {
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  email: {
                    ...(props.selectedScene.contact.email && props.selectedScene.contact.email),
                    name: e.target.value,
                  },
                })
              );
            }}
            onBlur={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: props.selectedScene.contact },
                  projectHasDraftScenes
                )
              );
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.telephone && props.selectedScene.contact.telephone.value}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: {
                      ...props.selectedScene.contact,
                      telephone: {
                        ...(props.selectedScene.contact.telephone && props.selectedScene.contact.telephone),
                        value: props.selectedScene.contact.telephone && !props.selectedScene.contact.telephone.value,
                      },
                    },
                  },
                  projectHasDraftScenes
                )
              );
            }}
          />
          <input
            type="text"
            name="contact"
            value={
              props.selectedScene.contact.telephone
                ? props.selectedScene.contact.telephone.name
                : t.sceneTab.contactSection.telephone
            }
            className={styles.otherInput}
            onChange={(e) => {
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  telephone: {
                    ...(props.selectedScene.contact.telephone && props.selectedScene.contact.telephone),
                    name: e.target.value,
                  },
                })
              );
            }}
            onBlur={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: props.selectedScene.contact },
                  projectHasDraftScenes
                )
              );
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.other && props.selectedScene.contact.other.value}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: {
                      ...props.selectedScene.contact,
                      other: {
                        ...(props.selectedScene.contact.other && props.selectedScene.contact.other),
                        value: props.selectedScene.contact.other && !props.selectedScene.contact.other.value,
                      },
                    },
                  },
                  projectHasDraftScenes
                )
              );
            }}
          />
          <input
            type="text"
            name="contact"
            value={
              props.selectedScene.contact.other
                ? props.selectedScene.contact.other.name
                : t.sceneTab.contactSection.other
            }
            className={styles.otherInput}
            onChange={(e) => {
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  other: {
                    ...(props.selectedScene.contact.other && props.selectedScene.contact.other),
                    name: e.target.value,
                  },
                })
              );
            }}
            onBlur={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: props.selectedScene.contact },
                  projectHasDraftScenes
                )
              );
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="checkbox"
            checked={props.selectedScene.contact.tos}
            onChange={() => {
              props.dispatch(
                props.updateScene(
                  props.token,
                  props.selectedScene._id,
                  { contact: { ...props.selectedScene.contact, tos: !props.selectedScene.contact.tos } },
                  projectHasDraftScenes
                )
              );
            }}
          />
          <label className={styles.inputLabel}>{t.sceneTab.contactSection.TOS}</label>
        </div>
        {props.selectedScene.contact.tos && (
          <div>
            <label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.TOSLink}</label>
            <RichText
              selectedScene={props.selectedScene}
              onBlur={(link) => {
                if (link) {
                  props.dispatch(
                    props.updateScene(
                      props.token,
                      props.selectedScene._id,
                      { contact: { ...props.selectedScene.contact, tosLink: link } },
                      projectHasDraftScenes
                    )
                  );
                } else {
                  props.dispatch(
                    props.updateScene(
                      props.token,
                      props.selectedScene._id,
                      { contact: props.selectedScene.contact },
                      projectHasDraftScenes
                    )
                  );
                }
              }}
              onChange={(e) => {
                props.dispatch(
                  props.updateSceneProp(props.selectedScene._id, 'contact', {
                    ...props.selectedScene.contact,
                    tosLink: e,
                  })
                );
              }}
              defaultValue={props.selectedScene.contact.tosLink && props.selectedScene.contact.tosLink}
              light
            />
          </div>
        )}
        <div>
          <label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.email}:</label>
          <Input
            type="text"
            name="contact"
            value={props.selectedScene.contact.emailLink}
            onChange={(e) =>
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  emailLink: e.target.value,
                })
              )
            }
            onBlur={() => {
              if (
                props.selectedScene.contact.emailLink.length === 0 ||
                validateEmail(props.selectedScene.contact.emailLink)
              ) {
                props.dispatch(
                  props.updateScene(
                    props.token,
                    props.selectedScene._id,
                    { contact: props.selectedScene.contact },
                    projectHasDraftScenes
                  )
                );
              }
            }}
            ternary
            error={
              props.selectedScene &&
              props.selectedScene.contact.emailLink &&
              props.selectedScene.contact.emailLink.length > 0 &&
              !validateEmail(props.selectedScene.contact.emailLink) &&
              t.sceneTab.errors.email
            }
          />
        </div>

        <div>
          <label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.ccEmail}:</label>
          <Input
            type="text"
            name="contact"
            placeholder={props.selectedScene.contact.ccEmail || t.sceneTab.contactSection.ccEmailPlaceholder}
            value={props.selectedScene.contact.ccEmail}
            onChange={(e) =>
              props.dispatch(
                props.updateSceneProp(props.selectedScene._id, e.target.name, {
                  ...props.selectedScene.contact,
                  ccEmail: e.target.value,
                })
              )
            }
            onBlur={() => {
              if (
                (props.selectedScene.contact.ccEmail && props.selectedScene.contact.ccEmail.length === 0) ||
                validateEmail(props.selectedScene.contact.ccEmail)
              ) {
                props.dispatch(
                  props.updateScene(
                    props.token,
                    props.selectedScene._id,
                    { contact: props.selectedScene.contact },
                    projectHasDraftScenes
                  )
                );
              }
            }}
            ternary
            error={
              props.selectedScene &&
              props.selectedScene.contact.ccEmail &&
              props.selectedScene.contact.ccEmail.length > 0 &&
              !validateEmail(props.selectedScene.contact.ccEmail) &&
              t.sceneTab.errors.email
            }
          />
        </div>
        <div className={styles.inputGroup}>
          <Answer
            key={0}
            index={0}
            answer={props.selectedScene.answers[0]}
            scenes={props.scenes}
            selectedScene={props.selectedScene}
            token={props.token}
            dispatch={props.dispatch}
            updateSceneAnswer={updateSceneAnswer}
            updateAnswer={updateAnswer}
            removeAnswer={removeAnswer}
            contact
          />
        </div>
      </div>
    </SidebarBox>
  );
}

export default ContactBox;
